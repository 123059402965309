import "./index.scss";
import React, { useState } from "react";
import { Modal, Button } from "antd";
import { InfoCircleFilled, CheckCircleFilled } from "@ant-design/icons";
import wrapper from "./wrapper";

const MessageModal = (props) => {
  const [visible, setVisible] = useState(true);

  const {
    /* 标题 */
    title,
    /* 关闭触发的回调 */
    onCancel,
    /* 销毁组件回调(高阶组件自定义) */
    closeDialog,
    /* 确定触发的回调 */
    onOk,
    /* 类型 warning 和 默认 */
    type,
    /* 详细内容 */
    description,
    /* 提示内容 */
    message,
    /* 确认按钮文字 */
    okText,
    /* 取消按钮文字 */
    cancelText,
    /* 不要按钮 */
    noBtn,
    /* 不要图标 */
    noIcon,
    /* 不要图标的情况下弹出框内不距离头的距离 */
    paddingTop,
    /* 详情文本信息 */
    infoText,
    /* 点击蒙层是否允许关闭 */
    maskClosable,
    /* 弹窗权值 */
    zIndex
  } = props;

  const handleCancel = () => {
    onCancel && onCancel();
    setVisible(false);
    setTimeout(() => {
      closeDialog();
    }, 500);
  };

  const handleOk = () => {
    onOk && onOk();
    setVisible(false);
    setTimeout(() => {
      closeDialog();
    }, 500);
  };

  const getStyleOptions = () => {
    let color = "";
    let icon = null;
    switch (type) {
      case "warning":
        color = "#FE545F";
        icon = <InfoCircleFilled style={{ color: "#FE545F" }} />;
        break;
      default:
        color = "#0068B2";
        icon = <CheckCircleFilled style={{ color: "#0068B2" }} />;
        break;
    }
    return {
      color,
      icon,
      btnStyle: {
        background: color,
        borderColor: color,
      },
    };
  };

  const StyleOptions = getStyleOptions();

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={handleCancel}
      //   onOk={handleOk}
      wrapClassName="messageModal"
      centered
      footer={null}
      maskClosable={maskClosable}
      zIndex={zIndex}
    >
      <div className="messageModalContent" style={{display:infoText?'block':'flex'}}>
        {!noIcon && <div className="iconBox">{StyleOptions.icon}</div>}

        {description && (
          <div
            className="descriptionBox"
            style={{ marginTop: `${paddingTop || 0}px` }}
          >
            {description}
          </div>
        )}
        {message && <div className="messageBox">{message}</div>}
        {infoText && <div className="infoTextBox">{infoText}</div>}
        {!noBtn && (
          <div className="drawerFooterBtnBox btnBox">
            <Button
              className="noBg"
              onClick={() => {
                handleCancel();
              }}
            >
              {cancelText || "取消"}
            </Button>
            <Button
              type="primary"
              style={StyleOptions.btnStyle}
              onClick={() => {
                handleOk();
              }}
            >
              {okText || "确定"}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default wrapper(MessageModal);
