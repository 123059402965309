import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./index.scss";
import projectApi from "../../../apis/project";
import {
  message,
  Button,
  Typography,
  Result,
  Tooltip,
  Pagination,
  Modal,
  Table,
} from "antd";
import {
  CopyOutlined,
  FileSearchOutlined,
  CheckOutlined,
  LoadingOutlined,
  EnterOutlined,
} from "@ant-design/icons";
import { useGetState } from "ahooks";
import Scrollbars from "react-custom-scrollbars";
import CompanyInfoDrawer from "../../../components/CompanyInfoDrawer";
import PersonInfoDrawer from "../../../components/PersonInfoDrawer";
import {
  addMillimeter,
  addMillimeterAndInit,
  downloadFile,
  guid,
} from "../../../unit/unit";
import MySpin from "../../../components/MySpin";
import ReactDOMServer from "react-dom/server";
import CaseDetailsDrawer from "../JudicialCases/components/CaseDetailsDrawer";
import DetailsDrawer from "../../../components/DetailsDrawer";
import MessageModal from "../../../components/MessageModal";
import { useSelector } from "react-redux";
import hczPng from "../../../assets/hcz.png";
import cxhcIcon from "../../../assets/cxhc.svg";
import xzIcon from "../../../assets/xztb.svg";

const { Paragraph } = Typography;

const VerificationReport = (props) => {
  const { isInner, getRiskTaskProcess, notFinished } = props;
  const { id } = useParams();
  const navigate = useNavigate();
  const [pageLoading, setPageLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [configList, setConfigList] = useState([]);
  const [riskTaskList, setRiskTaskList, getRiskTaskList] = useGetState([]);

  const [caseDrawerVisible, setCaseDrawerVisible] = useState(false);
  const [caseDetailInfo, setCaseDetailInfo] = useState({});
  const [detailsDrawerVisible, setDetailsDrawerVisible] = useState(false);
  const [choiceItem, setChoiceItem] = useState(null);

  const [detailItem, setDetailItem, getDetailItem] = useGetState({});
  const [allDetailItems, setAllDetailItems, getAllDetailItems] = useGetState(
    {}
  );
  const [verificationTime, setVerificationTime] = useState(null);
  const projectInfo = useSelector((state) => state.Project.projectInfo);
  const [copySuccess, setCopySuccess] = useState(false);

  const [tablePageObj, setTablePageObj, getTablePageObj] = useGetState({});

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [modalType, setModalType] = useState(null);
  const [historicalEvolutionData, setHistoricalEvolutionData] = useState([]);
  const [rowItemRule, setRowItemRule] = useState({});

  const styleObj = useRef({
    verificationReportPageContent: {
      width: "1000px",
      minHeight: "100%",
      backgroundColor: "#ffffff",
      borderRadius: "10px",
      padding: "20px",
      boxSizing: "border-box",
    },
    verificationReportContainer: {
      width: "100%",
      height: "100%",
      padding: "0 12px",
      boxSizing: "border-box",
    },
    verificationReportTitleBox: {
      fontSize: "30px",
      color: "#313131",
      textAlign: "center",
      fontWeight: "bold",
      margin: "20px 0 20px 0",
      position: "relative",
    },
    verificationReportDownloadIcon: {
      position: "absolute",
      bottom: "13px",
      right: "0px",
      fontSize: "20px",
      cursor: "pointer",
    },
    dateBox: {
      width: "100%",
      height: "30px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    btnFloatSpan: {
      float: "right",
      color: "#0068B2",
      fontSize: "14px",
      // marginLeft: "5px",
      cursor: "pointer",
      // borderBottom: "1px solid #0068B2",
    },
    dateSpan: {
      fontSize: "12px",
      color: "#9E9E9E",
    },
    numSpan: {
      fontSize: "16px",
      color: "#0068B2",
      fontWeight: "bold",
    },
    reportDetailBox: {
      width: "100%",
    },
    errorStatusTextBox: {
      width: "100%",
      height: "30px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: "10px",
    },
    errorStatusTextSpan: {
      fontSize: "16px",
      color: "#FE545F",
      fontWeight: "bold",
      display: "flex",
      alignItems: "center",
    },
    errorStatusTextSpanLine: {
      width: "2px",
      height: "14px",
      background: "#FE545F",
      marginRight: "8px",
    },
    showCompleteReportSpan: {
      fontSize: "12px",
      color: "#0068B2",
      cursor: "pointer",
    },
    errorTextBox: {
      width: "100%",
      height: "30px",
      background: "rgba(254,84,95,0.15)",
      borderRadius: "2px 2px 2px 2px",
      fontSize: "16px",
      color: "#FE545F",
      fontWeight: "bold",
      lineHeight: "30px",
      margin: "20px 0",
    },
    errorTextSpan: {
      display: "inline-block",
      background: "#FE545F",
      width: "60px",
      height: "100%",
      color: "#ffffff",
      textAlign: "center",
      marginRight: "5px",
    },
    failTextBox: {
      background: "rgba(254,192,84,0.15)",
      color: "#FEC054",
    },
    failTextSpan: {
      background: "#FEC054",
    },
    detailTable: {
      width: "100%",
    },
    detailTableHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      height: "34px",
      fontSize: "16px",
      color: "#FFFFFF",
      lineHeight: "34px",
      fontWeight: "bold",
    },
    detailTableHeaderItem: {
      background: "#A2A7AA",
      height: "100%",
      textAlign: "center",
    },
    detailTableItemBox: {
      display: "flex",
      alignItems: "stretch",
      justifyContent: "space-between",
      width: "100%",
      minHeight: "34px",
      fontSize: "16px",
      color: "#FFFFFF",
      lineHeight: "34px",
      //   fontWeight: "bold",
    },
    detailTableItem: {
      background: "#F8F8F9",
      display: "flex",
      alignItems: "center",
    },
    detailTableItemLogoBox: {
      display: "flex",
      alignItems: "center",
      paddingLeft: "40px",
      boxSizing: "border-box",
      // justifyContent: "center",
      fontSize: "14px",
      color: "#535353",
      width: "100%",
    },
    detailTableItemStatusBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "14px",
      color: "#535353",
      width: "100%",
    },
    detailTableItemStatusBoxBtnSpan: {
      cursor: "pointer",
      color: "#0068B2",
      marginLeft: "5px",
      // userSelect: "none",
    },
    detailTableItemDetailItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexWrap: "wrap",
      fontSize: "14px",
      padding: "6px 0",
      boxSizing: "border-box",
      width: "100%",
      // userSelect: "none",
      color: "#535353",
    },
    detailTableItemDetailItemBtn: {
      display: "inline-block",
      width: "100%",
      paddingLeft: "20px",
      boxSizing: "border-box",
      color: "#535353",
      lineHeight: "24px",
    },
    detailTableItemDetailItemBtnText: {
      marginLeft: "4px",
      color: "#0068B2",
      cursor: "pointer",
    },
    detailTableItemDetailInfoBox: {
      padding: "10px 40px 0 40px",
      boxSizing: "border-box",
    },
    detailItemContainer: {
      width: "100%",
      paddingLeft: "10px",
      boxSizing: "border-box",
    },
    detailItemContentBox: {
      width: "100%",
      fontSize: "12px",
      marginBottom: "4px",
      color: "#313131",
      // userSelect: "none",
      whiteSpace: "break-spaces",
      lineHeight: "20px",
    },
    detailItemDescriptions: {
      width: "100%",
      maxWidth: "1000px",
      fontSize: "12px",
      margin: "8px 0",
      color: "#313131",
      // userSelect: "none",
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      overflow: "hidden",
      borderTop: "1px solid rgba(0, 104, 178, 0.1)",
      borderRight: "1px solid rgba(0, 104, 178, 0.1)",
      borderSpacing: "0px",
      borderCollapse: "collapse",
      cellspacing: 0,
      // border: "1px solid rgba(0, 104, 178, 0.1)",
    },
    detailItemDescriptionsItemBox: {
      width: "100%",
      display: "flex",
      margin: "0px",
    },
    detailItemDescriptionsItemLabel: {
      minHeight: "30px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "150px",
      padding: "6px",
      boxSizing: "border-box",
      background: "rgb(242, 246, 250)",
      borderBottom: "1px solid rgba(0, 104, 178, 0.1)",
      borderLeft: "1px solid rgba(0, 104, 178, 0.1)",
      borderRight: "1px solid rgba(0, 104, 178, 0.1)",
    },
    detailItemDescriptionsItemContent: {
      minHeight: "30px",
      display: "flex",
      alignItems: "center",
      width: "calc(100% - 150px)",
      padding: "6px 10px",
      boxSizing: "border-box",
      borderBottom: "1px solid rgba(0, 104, 178, 0.1)",
      wordBreak: "break-all",
    },
    detailItemTableItemContent: {
      width: "100%",
      maxWidth: "1000px",
      fontSize: "12px",
      margin: "8px 0",
      color: "#313131",
      // userSelect: "none",
      boxSizing: "border-box",
      borderTop: "1px solid rgba(0, 104, 178, 0.1)",
      borderRight: "1px solid rgba(0, 104, 178, 0.1)",
      borderSpacing: "0px",
      borderCollapse: "collapse",
      cellspacing: 0,
    },
    detailItemTableTr: {
      minHeight: "30px",
      width: "100%",
      padding: "0px",
      boxSizing: "border-box",
    },
    detailItemTableTh: {
      padding: "6px",
      textAlign: "center",
      boxSizing: "border-box",
      background: "rgb(242, 246, 250)",
      borderBottom: "1px solid rgba(0, 104, 178, 0.1)",
      borderLeft: "1px solid rgba(0, 104, 178, 0.1)",
    },
    detailItemTableTd: {
      padding: "6px",
      textAlign: "center",
      boxSizing: "border-box",
      borderBottom: "1px solid rgba(0, 104, 178, 0.1)",
      borderLeft: "1px solid rgba(0, 104, 178, 0.1)",
    },
    summaryTable: {
      width: "calc(100% - 10px)",
      marginLeft: "10px",
    },
    summaryTableBox: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      maxWidth: "1000px",
      fontSize: "12px",
      margin: "8px 0",
      color: "#313131",
      // userSelect: "none",
      boxSizing: "border-box",
      borderTop: "1px solid rgba(0, 104, 178, 0.1)",
      borderRight: "1px solid rgba(0, 104, 178, 0.1)",
      borderSpacing: "0px",
      borderCollapse: "collapse",
      cellspacing: 0,
      borderRadius: "4px",
      overflow: "hidden",
    },
    summaryTableTr: {
      minHeight: "30px",
      width: "50%",
      padding: "0px",
      boxSizing: "border-box",
    },
    summaryTableTh: {
      display: "inline-block",
      width: "150px",
      minHeight: "30px",
      padding: "6px",
      textAlign: "center",
      boxSizing: "border-box",
      background: "rgb(242, 246, 250)",
      borderBottom: "1px solid rgba(0, 104, 178, 0.1)",
      borderLeft: "1px solid rgba(0, 104, 178, 0.1)",
    },
    summaryTableTd: {
      display: "inline-block",
      width: "calc(100% - 150px)",
      minHeight: "30px",
      padding: "6px",
      textAlign: "center",
      boxSizing: "border-box",
      borderBottom: "1px solid rgba(0, 104, 178, 0.1)",
      borderLeft: "1px solid rgba(0, 104, 178, 0.1)",
    },
  });

  //js实现复制到剪贴板，带格式复制
  const selectElementContents = (el) => {
    var body = document.body,
      range,
      sel;

    if (document.createRange && window.getSelection) {
      range = document.createRange();

      sel = window.getSelection();

      sel.removeAllRanges();

      try {
        range.selectNodeContents(el);

        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);

        sel.addRange(range);
      }
      document.execCommand("copy");
      //取消文本选中状态
      window.getSelection().empty();
      message.success("成功复制到剪贴板！");
      // alert("成功复制到剪贴板");
    }
  };

  //获取 风险核查配置项
  const getRiskItemsConfigs = () => {
    projectApi.getRiskItemsConfigs().then((res) => {
      console.log("getRiskItemsConfigs", res);
      if (res.code === 200) {
        setConfigList(res.data.data || []);
      } else {
        setConfigList([]);
        message.error(res.message);
      }
    });
  };

  //获取 风险核查数据
  const getRiskTaskData = (notLoading) => {
    if (!notLoading) {
      setPageLoading(true);
    }
    projectApi.getRiskTaskData(id).then((res) => {
      console.log("getRiskTaskData", res);
      setVerificationTime(res.data?.last_check_time);
      if (res.code === 200) {
        setRiskTaskList(res.data.data || []);
        //有重试中状态
        if (res.data.data?.some((_) => _?.risk_res === 10)) {
          //设置定时器 重新获取状态
          setTimeout(() => {
            getRiskTaskData(true);
          }, 3000);
        }
      } else {
        setRiskTaskList([]);
        message.error(res.message);
      }
      setPageLoading(false);
    });
  };

  //获取 风险核查某条数据详情
  const getDetailFunc = (item, parentItem, isAll) => {
    projectApi.getRiskTaskDetail(id, item.detail_id).then((res) => {
      console.log(res, "getRiskTaskDetail");
      if (res.code === 200) {
        setDetailItem({
          parent_id: parentItem.id,
          detail_id: item.detail_id,
          data: res.data,
          isAll: !!isAll,
        });
      } else {
        message.error(res.message);
        setDetailItem({});
      }
    });
  };

  //重试核查某条
  const reCheckFunc = (item) => {
    projectApi.reCheckRiskOne(id, item?.id).then((res) => {
      if (res?.code === 200) {
        getRiskTaskData(true);
      } else {
        message.error(res.message);
      }
    });
  };

  const reCheckRisk = () => {
    MessageModal({
      type: "warning",
      description: "确定要重新核查吗？",
      title: "重新核查",
      onOk: () => {
        projectApi.reCheckRisk(id).then((res) => {
          if (res.code === 200) {
            if (isInner) {
              getRiskTaskProcess();
            } else {
              getRiskTaskProcess();
              // navigate(`/company/${id}`);
            }
          } else {
            message.error(res.message);
          }
        });
      },
      onCancel: () => {},
    });
  };

  useEffect(() => {
    getRiskItemsConfigs();
    getRiskTaskData();
  }, [notFinished]);

  //查看公司详情
  const showCompanyInfoDrawer = (params) => {
    CompanyInfoDrawer(params);
  };

  //查看人员详情
  const showPersonInfoDrawer = (params) => {
    PersonInfoDrawer(params);
  };

  //获取 风险核查 所有数据详情 并下载
  const getAllDataDetailFunc = () => {
    let detailList = [];
    getRiskTaskList().forEach((_) => {
      (_?.detail_list || []).forEach((__) => {
        detailList.push({
          ..._,
          ...__,
        });
      });
    });

    projectApi
      ?.getRiskTaskListDetail({
        risk_id_list: detailList?.map((_) => _?.detail_id),
        project_id: id,
      })
      .then((res) => {
        console.log(res, "getRiskTaskListDetail");
        if (res.code === 200) {
          let allDetailItems = {};
          let data = res?.data || {};
          detailList?.forEach((item, __index) => {
            const info = item || {};
            const __item = data[item?.detail_id];
            let itemInfo = {
              data: {
                ...info,
                config: __item?.config,
                data: __item?.data,
                suggestionData: __item?.suggestionData,
              },
              detail_id: info?.detail_id,
              parent_id: info?.id,
            };
            let domHtml = initDetailContent(itemInfo, true);
            // let htmlText = ReactDOMServer.renderToStaticMarkup(
            //   <div>{domHtml}</div>
            // );

            allDetailItems[info?.detail_id] = domHtml;
          });

          setAllDetailItems(allDetailItems);

          setTimeout(() => {
            let domHtml = initDownloadPage();
            let html = ReactDOMServer.renderToStaticMarkup(
              <div
                id="verificationReportPageDownloadContent"
                style={styleObj?.current["verificationReportPageContent"]}
              >
                {domHtml}
              </div>
            );
            projectApi
              ?.downloadVerificationReport({
                html_str: html,
                project_id: id,
                company_name: projectInfo?.company_name,
              })
              .then((_res) => {
                if (_res.code === 200) {
                  downloadFile(_res?.data, id);
                } else {
                  message.error(_res.message);
                }
                setDownloadLoading(false);
              });
            // navigator.clipboard.writeText(JSON.stringify(html));
            // message.success("复制成功");
            // setDownloadLoading(false);
          }, 500);
        } else {
          message.error(res.message);
          setDownloadLoading(false);
        }
      });
  };

  //下载时 生成 报告界面的html
  const initDownloadPage = () => {
    let errorList = [],
      failList = [];

    getRiskTaskList()?.forEach((item) => {
      if (item?.risk_res === 1) {
        errorList.push(item);
      }
      if (item?.risk_res === 2 || item?.risk_res === 10) {
        failList.push(item);
      }
    });

    return (
      <div>
        {errorList?.map((item, index) => {
          return (
            <div>
              <h2>
                {index + 1}.{item?.risk_item_name}
              </h2>
              {item?.detail_list?.map((detailItem, _index) => {
                return (
                  <div style={{ paddingLeft: "20px" }}>
                    <h3 style={{ marginBottom: "0px" }}>
                      {_index + 1}.{detailItem?.title}
                    </h3>
                    {getAllDetailItems()[detailItem?.detail_id]}
                  </div>
                );
              })}
            </div>
          );
        })}
        {failList?.map((item) => {
          return (
            <div>
              <h1>{item?.risk_item_name}</h1>
            </div>
          );
        })}
      </div>
    );
  };

  //下载报告
  const downloadFunc = () => {
    setDownloadLoading(true);
    getAllDataDetailFunc();
  };

  //展开表格详情弹窗
  const handleTableModal = (columns, list, type) => {
    if (type === "table") {
      const tableColumns = columns?.map((cellItem) => {
        let obj = {
          title: cellItem.name,
          dataIndex: cellItem.key,
          align: "center",
          ...cellItem,
        };

        if (cellItem?.key === "serial") {
          obj.width = 70;
        }
        obj.render = (__, item) => {
          let text = null;
          if (cellItem?.type === "array") {
            text = "";
            (item[cellItem?.key] || [])?.forEach((_, i) => {
              text = text + (i === 0 ? "" : cellItem?.sep) + _;
            });
          } else if (cellItem?.need_millimeter) {
            text =
              item[cellItem?.key] &&
              addMillimeter(Number(item[cellItem?.key], 2));
            //加单位
            if (cellItem?.amount_unit) {
              text += cellItem?.amount_unit;
            }
          } else {
            text = item[cellItem?.key];
          }

          if (cellItem?.is_click) {
            return (
              <span
                style={{
                  color: "#0068B2",
                  cursor: "pointer",
                }}
                onClick={() => {
                  //事件详情
                  if (cellItem?.table_name) {
                    setChoiceItem({
                      id: { val: item[cellItem?.get_key] },
                      typeName: cellItem?.table_cn_name,
                      tableName: cellItem?.table_name,
                    });
                    setTimeout(() => {
                      setDetailsDrawerVisible(true);
                    }, 100);
                  } else if (item?.type === "Person") {
                    let params = {
                      projectId: id,
                      personName: item[cellItem?.key],
                      params: {
                        person_id: item[cellItem?.get_key],
                        person_name: item[cellItem?.key],
                        // company_id: item?.companyId?.val,
                      },
                    };
                    showPersonInfoDrawer(params);
                  } else if (item?.type === "Company") {
                    let params = {
                      projectId: id,
                      companyId: item[cellItem?.get_key],
                      companyName: item[cellItem?.key],
                    };
                    showCompanyInfoDrawer(params);
                  } else {
                    //案件详情
                    setCaseDetailInfo(item);
                    setTimeout(() => {
                      setCaseDrawerVisible(true);
                    }, 100);
                  }
                }}
              >
                {text}
              </span>
            );
          }
          if (cellItem?.ellipsis) {
            return (
              <Paragraph
                ellipsis={{
                  tooltip: {
                    title: text,
                    overlayClassName: "detailItemTableTdSharesTip",
                  },
                  rows: Number(cellItem?.ellipsis),
                  expandable: false,
                }}
                style={{
                  width: `100%`,
                  marginBottom: 0,
                }}
              >
                {text}
              </Paragraph>
            );
          }
          return <span>{text}</span>;
        };

        obj.ellipsis = false;
        return obj;
      });

      const tableData = list?.map((_, index) => {
        _.serial = index + 1;
        return _;
      });
      setTableColumns(tableColumns);
      setTableData(tableData);
    } else if (type === "article") {
      setHistoricalEvolutionData(list);
      setRowItemRule(columns);
    }
    setModalType(type);
    setTimeout(() => {
      setIsModalOpen(true);
    }, 100);
  };

  //初始化 详情内容dom渲染 函数
  const initDetailItemDom = (rows, info, data_index, data_len, isDownload) => {
    return (
      <>
        {rows?.map((rowItem) => {
          /* 前置条件 如果数据为空则不显示 */
          if (rowItem?.show_by_key && !info[rowItem?.show_by_key]) {
            return null;
          }
          /* 纯文本 */
          if (rowItem?.type === "text") {
            return <>{rowItem?.val}</>;
          }
          /* 末尾标点符号 最后一条显示 last_val */
          if (rowItem?.type === "symbol_text") {
            if (data_len - 1 === data_index) {
              return <>{rowItem?.last_val}</>;
            }
            return <>{rowItem?.val}</>;
          }
          /* 循环展示 纯文本 */
          if (rowItem?.type === "array") {
            return (info[rowItem?.val] || [])?.map((_, _index) => (
              <>
                {_index === 0 ? null : rowItem?.sep}
                {_ ?? "-"}
              </>
            ));
          }
          /* 变量 */
          if (rowItem?.type === "dynamic_text") {
            //显示长度
            if (rowItem?.show_length) {
              return <>{(info[rowItem?.val] || [])?.length ?? "-"}</>;
            }
            //可点击查看详情
            if (rowItem?.is_click) {
              return (
                <span
                  style={{
                    color: isDownload ? "unset" : "#0068B2",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (rowItem?.table_name) {
                      setChoiceItem({
                        id: { val: info[rowItem?.get_key] },
                        typeName: rowItem?.table_cn_name,
                        tableName: rowItem?.table_name,
                      });
                      setTimeout(() => {
                        setDetailsDrawerVisible(true);
                      }, 100);
                    } else if (rowItem?.is_url) {
                      window.open(info[rowItem?.get_key], guid());
                    } else {
                      setCaseDetailInfo(info);
                      setTimeout(() => {
                        setCaseDrawerVisible(true);
                      }, 100);
                    }
                  }}
                >
                  {info[rowItem?.val] ?? "-"}
                </span>
              );
            }
            return <>{info[rowItem?.val] ?? "-"}</>;
          }
          /* 人名 */
          if (rowItem?.type === "person") {
            //可点击查看详情
            if (rowItem?.is_click) {
              return (
                <span
                  style={{
                    color: isDownload ? "unset" : "#0068B2",
                    cursor: "pointer",
                    fontWeight: isDownload ? "bold" : null,
                  }}
                  onClick={() => {
                    let params = {
                      projectId: id,
                      personName: info[rowItem?.val],
                      params: {
                        person_id: info[rowItem?.get_key],
                        person_name: info[rowItem?.val],
                        // company_id: item?.companyId?.val,
                      },
                    };
                    showPersonInfoDrawer(params);
                  }}
                >
                  {info[rowItem?.val] ?? "-"}
                </span>
              );
            }
            return (
              <span style={{ color: isDownload ? "unset" : "#0068B2" }}>
                {info[rowItem?.val] ?? "-"}
              </span>
            );
          }
          /* 人名合集 */
          if (rowItem?.type === "person_list") {
            let len = (info[rowItem?.val] || [])?.length;
            return (info[rowItem?.val] || [])?.map((_, _index) => {
              let dom = null;
              //可点击查看详情
              if (rowItem?.is_click) {
                dom = (
                  <span
                    style={{
                      color: isDownload ? "unset" : "#0068B2",
                      cursor: "pointer",
                      fontWeight: isDownload ? "bold" : null,
                    }}
                    onClick={() => {
                      let params = {
                        projectId: id,
                        personName: _?.person_name,
                        params: {
                          person_id: _?.person_id,
                          person_name: _?.person_name,
                          // company_id: item?.companyId?.val,
                        },
                      };
                      showPersonInfoDrawer(params);
                    }}
                  >
                    {_?.person_name ?? "-"}
                  </span>
                );
              } else {
                dom = (
                  <span style={{ color: isDownload ? "unset" : "#0068B2" }}>
                    {_?.person_name ?? "-"}
                  </span>
                );
              }
              //是否省略 + 等
              let isSkip = false;
              if (_index + 1 === len) {
                if (rowItem?.skip_by_key) {
                  isSkip = !!info[rowItem?.skip_by_key];
                }
              }
              return (
                <>
                  {_index === 0 ? null : rowItem?.sep}
                  {dom}
                  {isSkip ? rowItem?.skip_show : null}
                </>
              );
            });
          }
          /* 公司名 */
          if (rowItem?.type === "company") {
            //可点击查看详情
            if (rowItem?.is_click) {
              return (
                <span
                  style={{
                    color: isDownload ? "unset" : "#0068B2",
                    cursor: "pointer",
                    fontWeight: isDownload ? "bold" : null,
                  }}
                  onClick={() => {
                    let params = {
                      projectId: id,
                      companyId: info[rowItem?.get_key],
                      companyName: info[rowItem?.val],
                    };
                    showCompanyInfoDrawer(params);
                  }}
                >
                  {info[rowItem?.val] ?? "-"}
                </span>
              );
            }
            return (
              <span style={{ color: isDownload ? "unset" : "#0068B2" }}>
                {info[rowItem?.val] ?? "-"}
              </span>
            );
          }
          /* 公司名合集 */
          if (rowItem?.type === "company_list") {
            let len = (info[rowItem?.val] || [])?.length;
            return (info[rowItem?.val] || [])?.map((_, _index) => {
              let dom = null;
              //可点击查看详情
              if (rowItem?.is_click) {
                dom = (
                  <span
                    style={{
                      color: isDownload ? "unset" : "#0068B2",
                      cursor: "pointer",
                      fontWeight: isDownload ? "bold" : null,
                    }}
                    onClick={() => {
                      let params = {
                        projectId: id,
                        companyId: _?.company_id,
                        companyName: _?.company_name,
                      };
                      showCompanyInfoDrawer(params);
                    }}
                  >
                    {_?.company_name ?? "-"}
                  </span>
                );
              } else {
                dom = (
                  <span style={{ color: isDownload ? "unset" : "#0068B2" }}>
                    {_?.company_name ?? "-"}
                  </span>
                );
              }
              //是否省略 + 等
              let isSkip = false;
              if (_index + 1 === len) {
                if (rowItem?.skip_by_key) {
                  isSkip = !!info[rowItem?.skip_by_key];
                }
              }
              return (
                <>
                  {_index === 0 ? null : rowItem?.sep}
                  {dom}
                  {isSkip ? rowItem?.skip_show : null}
                </>
              );
            });
          }
          /* 公司详情 */
          if (rowItem?.type === "company_info") {
            const list = Object.keys(rowItem?.rows || {})
              .map((key) => ({ key: key, ...rowItem?.rows[key] }))
              .sort((a, b) => a.index - b.index);

            let showArr = [];
            let count = 0;
            let arr = [];

            list?.forEach((_) => {
              if (count >= 100) {
                showArr.push(arr);
                arr = [];
                count = 0;
              }
              count = count + _.width;
              arr.push(_);
            });

            return (
              <>
                {/* 生成表格时上下间距 */}
                {isDownload && <div>{'\r\n'}</div>}
                <table>
                  <tbody style={styleObj?.current["detailItemDescriptions"]}>
                    {showArr.map((_item) => {
                      return (
                        <tr
                          style={
                            styleObj?.current["detailItemDescriptionsItemBox"]
                          }
                        >
                          {_item.map((item) => {
                            const _info = info[rowItem?.val];

                            return (
                              <>
                                <td
                                  style={
                                    styleObj?.current[
                                      "detailItemDescriptionsItemLabel"
                                    ]
                                  }
                                >
                                  {item.name}
                                </td>
                                <td
                                  style={{
                                    ...styleObj?.current[
                                      "detailItemDescriptionsItemContent"
                                    ],
                                    width: `calc(${item?.width + "%"} - ${
                                      styleObj?.current[
                                        "detailItemDescriptionsItemLabel"
                                      ]?.width
                                    })`,
                                  }}
                                >
                                  {item.need_millimeter
                                    ? _info[item.key]
                                      ? addMillimeterAndInit(
                                          _info[item.key],
                                          2
                                        ) +
                                        (item.join_field
                                          ? _info[item.join_field]
                                          : "")
                                      : "-"
                                    : _info[item.key] || "-"}
                                </td>
                              </>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {/* 生成表格时上下间距 */}
                {isDownload && <div>{'\r\n'}</div>}
              </>
            );
          }
          /* 映射关系 */
          if (rowItem?.type === "map") {
            let itemKey = info[rowItem?.get_key];
            let _rowItems = rowItem?.sus_map[itemKey] || [];
            return initDetailItemDom(
              _rowItems,
              info,
              data_index,
              data_len,
              isDownload
            );
          }
          /* 表格 */
          if (rowItem?.type === "table") {
            let key = rowItem?.val + "__" + data_index;
            let pageSize = isDownload ? null : rowItem?.page_size;

            const list = Object.keys(rowItem?.rows || {})
              .map((key, index) => ({
                key: key,
                ...rowItem?.rows[key],
              }))
              .sort((a, b) => a.index - b.index);

            list.unshift({
              key: "serial",
              name: "序号",
              width: "5%",
            });
            let showList = info[rowItem?.val] || [];
            let pageNumber = 1;
            const dataLen = (info[rowItem?.val] || [])?.length;
            if (isDownload) {
              if (pageSize) {
                if (getTablePageObj()[key]) {
                  pageNumber = getTablePageObj()[key];
                }
                showList = info[rowItem?.val]?.slice(
                  pageSize * (pageNumber - 1),
                  pageSize * pageNumber
                );
              }
              return (
                <>
                  {/* 生成表格时上下间距 */}
                  {isDownload && <div>{'\r\n'}</div>}
                  <table
                    style={styleObj?.current["detailItemTableItemContent"]}
                  >
                    <thead>
                      <tr style={styleObj?.current["detailItemTableTr"]}>
                        {list?.map((headRow) => {
                          return (
                            <th
                              style={{
                                ...styleObj?.current["detailItemTableTh"],
                                width: headRow?.width,
                                height: "100%",
                              }}
                            >
                              {headRow?.name}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {showList?.map((item, _index) => {
                        return (
                          <tr>
                            {list?.map((cellItem) => {
                              let text = null;
                              let dom = null;
                              //序号
                              if (cellItem?.key === "serial") {
                                if (pageSize) {
                                  text =
                                    (pageNumber - 1) * pageSize + _index + 1;
                                } else {
                                  text = _index + 1;
                                }
                              } else if (cellItem?.type === "array") {
                                text = "";
                                (item[cellItem?.key] || [])?.forEach((_, i) => {
                                  text =
                                    text + (i === 0 ? "" : cellItem?.sep) + _;
                                });
                              } else if (cellItem?.need_millimeter) {
                                text =
                                  item[cellItem?.key] &&
                                  addMillimeter(Number(item[cellItem?.key], 2));
                                //加单位
                                if (cellItem?.amount_unit) {
                                  text += cellItem?.amount_unit;
                                }
                              } else {
                                text = item[cellItem?.key];
                              }
                              text = text ?? "-";
                              if (cellItem?.is_click) {
                                dom = (
                                  <span
                                    style={{
                                      color: isDownload ? "unset" : "#0068B2",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      //事件详情
                                      if (cellItem?.table_name) {
                                        setChoiceItem({
                                          id: { val: item[cellItem?.get_key] },
                                          typeName: cellItem?.table_cn_name,
                                          tableName: cellItem?.table_name,
                                        });
                                        setTimeout(() => {
                                          setDetailsDrawerVisible(true);
                                        }, 100);
                                      } else if (item?.type === "Person") {
                                        let params = {
                                          projectId: id,
                                          personName: item[cellItem?.key],
                                          params: {
                                            person_id: item[cellItem?.get_key],
                                            person_name: item[cellItem?.key],
                                            // company_id: item?.companyId?.val,
                                          },
                                        };
                                        showPersonInfoDrawer(params);
                                      } else if (item?.type === "Company") {
                                        let params = {
                                          projectId: id,
                                          companyId: item[cellItem?.get_key],
                                          companyName: item[cellItem?.key],
                                        };
                                        showCompanyInfoDrawer(params);
                                      } else {
                                        //案件详情
                                        setCaseDetailInfo(item);
                                        setTimeout(() => {
                                          setCaseDrawerVisible(true);
                                        }, 100);
                                      }
                                    }}
                                  >
                                    {text}
                                  </span>
                                );
                              } else {
                                dom = text;
                              }
                              if (isDownload) {
                                return (
                                  <td
                                    style={{
                                      ...styleObj?.current["detailItemTableTd"],
                                      width: cellItem?.width,
                                    }}
                                  >
                                    {dom}
                                  </td>
                                );
                              }
                              return (
                                <td
                                  style={{
                                    ...styleObj?.current["detailItemTableTd"],
                                    width: cellItem?.width,
                                  }}
                                >
                                  {cellItem?.ellipsis ? (
                                    <Paragraph
                                      ellipsis={{
                                        tooltip: {
                                          title: text,
                                          overlayClassName:
                                            "detailItemTableTdSharesTip",
                                        },
                                        rows: Number(cellItem?.ellipsis),
                                        expandable: false,
                                      }}
                                      style={{
                                        width: `100%`,
                                        marginBottom: 0,
                                      }}
                                    >
                                      {dom}
                                    </Paragraph>
                                  ) : (
                                    dom
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {pageSize && dataLen > pageSize && !isDownload && (
                    <Pagination
                      className="reportTablePagination"
                      current={pageNumber}
                      pageSize={pageSize}
                      showSizeChanger={false}
                      showTotal={(total) => `共 ${total} 条`}
                      showTitle={true}
                      total={dataLen}
                      onChange={(page) => {
                        setTablePageObj((prev) => {
                          prev[key] = page;
                          return {
                            ...prev,
                          };
                        });
                      }}
                    />
                  )}
                  {isDownload && <div>{'\r\n'}</div>}
                </>
              );
            } else {
              return (
                <div
                  className="tableDetailBtn"
                  onClick={() => {
                    handleTableModal(list, info[rowItem?.val], "table");
                  }}
                >
                  {rowItem?.table_cn_name}
                  {">"}
                </div>
              );
            }
          }
          /* 历史沿革 文章 */
          if (rowItem?.type === "article") {
            if (isDownload) {
              return (info[rowItem?.val] || [])?.map((item) => {
                return (
                  <>
                    <h4
                      style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "#313131",
                        marginBottom: "0px",
                      }}
                    >
                      {item[rowItem?.title_key] ?? ""}
                    </h4>
                    <div
                      style={{
                        fontSize: "12px",
                        color: "#9e9e9e",
                        marginBottom: "6px",
                      }}
                    >
                      {item[rowItem?.text_key] ?? ""}
                    </div>
                  </>
                );
              });
            } else {
              return (
                <div
                  className="tableDetailBtn"
                  onClick={() => {
                    handleTableModal(rowItem, info[rowItem?.val], "article");
                  }}
                >
                  历史沿革{">"}
                </div>
              );
              return (
                <div className="articleTitleTextContent">
                  <div className="iconBox">
                    <Tooltip
                      placement="top"
                      title={copySuccess ? "复制成功" : "复制"}
                      arrowPointAtCenter
                    >
                      {copySuccess ? (
                        <CheckOutlined className="copySuccessIcon" />
                      ) : (
                        <CopyOutlined
                          className="copyIcon"
                          onClick={() => {
                            selectElementContents(
                              document.getElementById("copyDom")
                            );
                          }}
                        />
                      )}
                    </Tooltip>
                  </div>
                  <Scrollbars
                    autoHide={true}
                    autoHideTimeout={5000}
                    style={{ height: "100%", width: "100%" }}
                  >
                    <div style={{ padding: "10px" }} id="copyDom">
                      {(info[rowItem?.val] || []).map((item) => (
                        <div className="titleTextItem">
                          <div className="titleTextItemTitle">
                            {item[rowItem?.title_key] ?? ""}
                          </div>
                          <div className="titleTextItemText">
                            {item[rowItem?.text_key] ?? ""}
                          </div>
                        </div>
                      ))}
                    </div>
                  </Scrollbars>
                </div>
              );
            }
          }
          /* 循环某个值-渲染 */
          if (rowItem?.type === "suspected") {
            const init = () => {
              let len = (info[rowItem?.val] || [])?.length;
              return (info[rowItem?.val] || [])?.map((_, _index) => {
                //是否省略 + 等
                let isSkip = false;
                if (_index + 1 === len) {
                  if (rowItem?.skip_by_key) {
                    isSkip = !!info[rowItem?.skip_by_key];
                  }
                }
                //一行显示
                if (rowItem?.is_one_line) {
                  return (
                    <>
                      {_index === 0 ? null : rowItem?.sep}
                      {initDetailItemDom(
                        rowItem?.inner_detail,
                        _,
                        _index,
                        len,
                        isDownload
                      )}
                      {isSkip ? rowItem?.skip_show : null}
                    </>
                  );
                }
                return (
                  <div style={{ marginBottom: "6px" }}>
                    {initDetailItemDom(
                      rowItem?.inner_detail,
                      _,
                      _index,
                      len,
                      isDownload
                    )}
                    {isSkip ? rowItem?.skip_show : null}
                  </div>
                );
              });
            };
            //一行显示
            if (rowItem?.is_one_line) {
              return init();
            }
            return <div style={{ marginTop: "6px" }}>{init()}</div>;
          }
          return <span>---===---</span>;
        })}
      </>
    );
  };

  //初始化 详情整体dom渲染 函数
  const initDetailContent = (item, isDownload) => {
    return (
      <div
        style={{
          ...styleObj?.current["detailTableItemDetailInfoBox"],
          padding: isDownload ? "10px 0 0 0" : "10px 40px 0 40px",
        }}
      >
        <div
          style={{
            ...styleObj?.current["errorStatusTextBox"],
            margin: "0",
          }}
        >
          <span
            style={{
              ...styleObj?.current["errorStatusTextSpan"],
              color: "#000000",
              fontSize: "14px",
            }}
          >
            <span
              style={{
                ...styleObj?.current["errorStatusTextSpanLine"],
                display: "inline-block",
                background:
                  "linear-gradient( 223deg, #006BB3 0%, #15D2D6 100%)",
              }}
            ></span>
            异常情况
          </span>
        </div>
        {/* 异常情况 */}
        <div style={styleObj?.current["detailItemContainer"]}>
          {item.data?.data?.map((_, _index) => (
            <div style={styleObj?.current["detailItemContentBox"]}>
              {initDetailItemDom(
                item.data?.config?.detail,
                _,
                _index,
                item.data?.data?.length,
                isDownload
              )}
            </div>
          ))}
        </div>
        <div
          style={{
            ...styleObj?.current["errorStatusTextBox"],
            margin: "0",
          }}
        >
          <span
            style={{
              ...styleObj?.current["errorStatusTextSpan"],
              color: "#000000",
              fontSize: "14px",
            }}
          >
            <span
              style={{
                ...styleObj?.current["errorStatusTextSpanLine"],
                display: "inline-block",
                background:
                  "linear-gradient( 223deg, #006BB3 0%, #15D2D6 100%)",
              }}
            ></span>
            影响与建议
          </span>
        </div>
        {/* 影响与建议 */}
        <div style={styleObj?.current["detailItemContainer"]}>
          <div style={styleObj?.current["detailItemContentBox"]}>
            {initDetailItemDom(
              item.data?.config?.suggestion || [],
              item.data?.suggestionData || {},
              0,
              1,
              isDownload
            )}
          </div>
        </div>
      </div>
    );
  };

  //初始化 数据表格样式 函数
  const initDetailTableDom = (list, isDownload, isAll) => {
    const columns = [
      {
        width: "20%",
        title: "核查项",
        render: (item) => {
          return (
            <div style={styleObj?.current["detailTableItemLogoBox"]}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "22px",
                  height: "22px",
                  borderRadius: "50%",
                  background: item?.logo_bg_color || "#6CAEFF",
                  marginRight: "4px",
                }}
              >
                <img
                  src={item?.full_risk_logo_url}
                  alt=""
                  srcset=""
                  style={{
                    display: "block",
                    width: "20px",
                    height: "20px",
                  }}
                />
              </div>
              {item.risk_item_name}
            </div>
          );
        },
      },
      {
        width: "calc(40% - 2px)",
        title: "核查结果",
        render: (item) => {
          let str = "正常",
            color = "#61D4AD";
          //关注
          if (item.risk_res === 1) {
            str = "关注";
            color = "#E14942";
          }
          //失败
          if (item.risk_res === 2) {
            str = "核查失败";
            color = "#FEC054";
          }
          //重试中
          if (item.risk_res === 10) {
            str = "重试中...";
            color = "#0068B2";
          }
          return (
            <div
              style={{
                ...styleObj?.current["detailTableItemStatusBox"],
                color,
              }}
            >
              {item.risk_res === 10 ? (
                <LoadingOutlined
                  style={{
                    display: "block",
                    fontSize: "16px",
                    marginRight: "4px",
                  }}
                />
              ) : (
                <img
                  src={item?.risk_res_logo}
                  alt=""
                  srcset=""
                  style={{
                    display: "block",
                    width: "16px",
                    height: "16px",
                    marginRight: "4px",
                  }}
                />
              )}
              {str}
              {item.risk_res === 2 && (
                <span
                  style={styleObj?.current["detailTableItemStatusBoxBtnSpan"]}
                  onClick={() => {
                    reCheckFunc(item);
                  }}
                >
                  重试
                </span>
              )}
            </div>
          );
        },
      },
      {
        width: "calc(40% - 2px)",
        title: "详情",
        render: (item) => {
          return (
            <div style={styleObj?.current["detailTableItemDetailItem"]}>
              {(item?.detail_list || [])?.length > 0 ? (
                (item?.detail_list || [])?.map((_) => (
                  <span
                    style={{
                      ...styleObj?.current["detailTableItemDetailItemBtn"],
                      fontWeight:
                        getDetailItem()?.detail_id === _.detail_id &&
                        !!isAll === getDetailItem()?.isAll
                          ? "bold"
                          : "unset",
                      color:
                        getDetailItem()?.detail_id === _.detail_id &&
                        !!isAll === getDetailItem()?.isAll
                          ? "#000"
                          : "#535353",
                    }}
                  >
                    {_.title}
                    {!isDownload && (
                      <span
                        style={
                          styleObj?.current["detailTableItemDetailItemBtnText"]
                        }
                        onClick={() => {
                          setTablePageObj({});
                          if (
                            getDetailItem()?.detail_id === _.detail_id &&
                            !!isAll === getDetailItem()?.isAll
                          ) {
                            setDetailItem({});
                            return;
                          }
                          getDetailFunc(_, item, isAll);
                        }}
                      >
                        {getDetailItem()?.detail_id === _.detail_id &&
                        !!isAll === getDetailItem()?.isAll
                          ? "收起"
                          : "详情"}
                      </span>
                    )}
                  </span>
                ))
              ) : (
                <span style={styleObj?.current["detailTableItemDetailItemBtn"]}>
                  -
                </span>
              )}
            </div>
          );
        },
      },
    ];

    return (
      <div style={styleObj?.current["detailTable"]}>
        <div style={styleObj?.current["detailTableHeader"]}>
          {columns?.map((_) => (
            <div
              style={{
                ...styleObj?.current["detailTableHeaderItem"],
                width: _?.width,
              }}
            >
              {_.title}
            </div>
          ))}
        </div>

        {isAll
          ? list?.map((_item, index) => (
              <>
                <div style={{ marginTop: index === 0 ? "0px" : "2px" }}>
                  <div style={styleObj?.current["detailTableItemBox"]}>
                    {columns?.map((_, _index) => (
                      <div
                        style={{
                          ...styleObj?.current["detailTableItem"],
                          width: _?.width,
                          color: "#313131",
                          fontWeight: "bold",
                          paddingLeft: "20px",
                          height: "100%",
                          minHeight: "34px",
                        }}
                      >
                        {_index === 0 ? _item?.name : ""}
                      </div>
                    ))}
                  </div>
                </div>
                {(_item?.list || [])?.map((item, index) => (
                  <div style={{ marginTop: "2px" }}>
                    <div style={styleObj?.current["detailTableItemBox"]}>
                      {columns?.map((_) => (
                        <div
                          style={{
                            ...styleObj?.current["detailTableItem"],
                            width: _?.width,
                          }}
                        >
                          {_.render(item)}
                        </div>
                      ))}
                    </div>
                    {isDownload
                      ? (item?.detail_list || [])?.map((__) =>
                          initDetailContent({
                            parent_id: item.id,
                            detail_id: __.detail_id,
                            data: getAllDetailItems()[__.detail_id] || {
                              data: [],
                              config: {},
                              suggestionData: {},
                            },
                          })
                        )
                      : getDetailItem()?.parent_id === item.id &&
                        !!isAll === getDetailItem()?.isAll &&
                        initDetailContent(getDetailItem(), isDownload)}
                  </div>
                ))}
              </>
            ))
          : list?.map((_item, index) => (
              <div style={{ marginTop: index === 0 ? "0px" : "2px" }}>
                <div style={styleObj?.current["detailTableItemBox"]}>
                  {columns?.map((_) => (
                    <div
                      style={{
                        ...styleObj?.current["detailTableItem"],
                        width: _?.width,
                      }}
                    >
                      {_.render(_item)}
                    </div>
                  ))}
                </div>
                {isDownload
                  ? (_item?.detail_list || [])?.map((__) =>
                      initDetailContent({
                        parent_id: _item.id,
                        detail_id: __.detail_id,
                        data: getAllDetailItems()[__.detail_id] || {
                          data: [],
                          config: {},
                          suggestionData: {},
                        },
                      })
                    )
                  : getDetailItem()?.parent_id === _item.id &&
                    !!isAll === getDetailItem()?.isAll &&
                    initDetailContent(getDetailItem(), isDownload)}
              </div>
            ))}
      </div>
    );
  };

  //初始化 异常结果数据样式 函数
  const initDomFunc = (data, isDownload) => {
    let errorList = [],
      failList = [];

    data?.forEach((item) => {
      if (item?.risk_res === 1) {
        errorList.push(item);
      }
      if (item?.risk_res === 2 || item?.risk_res === 10) {
        failList.push(item);
      }
    });

    return (
      <>
        {errorList?.length > 0 && (
          <div style={styleObj?.current["errorTextBox"]}>
            <span style={styleObj?.current["errorTextSpan"]}>
              {errorList?.length}
            </span>
            个异常结果
          </div>
        )}
        {errorList?.length > 0 && initDetailTableDom(errorList, isDownload)}

        {failList?.length > 0 && (
          <div
            style={{
              ...styleObj?.current["errorTextBox"],
              ...styleObj?.current["failTextBox"],
            }}
          >
            <span
              style={{
                ...styleObj?.current["errorTextSpan"],
                ...styleObj?.current["failTextSpan"],
              }}
            >
              {failList?.length}
            </span>
            个核查失败
          </div>
        )}

        {failList?.length > 0 && initDetailTableDom(failList, isDownload)}
      </>
    );
  };

  //初始化 全部核查结果样式 函数
  const initAllDetailTableDom = (data, isDownload) => {
    let list = [];
    let obj = {};

    data?.forEach((item, index) => {
      if (obj[item?.risk_type_name]) {
        obj[item?.risk_type_name]?.list?.push(item);
      } else {
        obj[item?.risk_type_name] = {
          index: index,
          name: item?.risk_type_name,
          list: [item],
        };
      }
    });

    Object?.keys(obj)
      ?.sort((a, b) => obj[a]?.index - obj[b]?.index)
      ?.forEach((key) => {
        list.push(obj[key]);
      });

    return initDetailTableDom(list, isDownload, true);
  };

  //初始化全部通过 界面样式 函数
  const initAllAdoptPage = (data) => {
    let len = data?.length;

    let errorLen = 0,
      failLen = 0,
      successLen = 0;

    data?.forEach((item) => {
      if (item?.risk_res === 1) {
        errorLen += 1;
      } else if (item?.risk_res === 2 || item?.risk_res === 10) {
        failLen += 1;
      } else {
        successLen += 1;
      }
    });
    return (
      <div className="allAdoptPageContent">
        <Result
          status="success"
          title="核查通过"
          subTitle={
            <div>
              未发现异常项
              <span
                className="clickSpan"
                onClick={() => {
                  window.open(
                    window.location?.origin +
                      `/company/${id}/verificationReport`,
                    guid()
                  );
                }}
              >
                查看完整报告
              </span>
            </div>
          }
        />
        <div className="allAdoptPageTableBox">
          <div className="tableItem tableActiveItem">核查项</div>
          <div className="tableItem">{len}项</div>
          <div className="tableItem">
            <div className="tableInnerItem tableActiveItem">异常项</div>
            <div className="tableInnerItem tableActiveItem">正常项</div>
            <div className="tableInnerItem tableActiveItem">失败项</div>
          </div>
          <div className="tableItem">
            <div className="tableInnerItem" style={{ color: "#FE545F" }}>
              {errorLen}
            </div>
            <div className="tableInnerItem" style={{ color: "#61D4AD" }}>
              {successLen}
            </div>
            <div className="tableInnerItem" style={{ color: "#FEC054" }}>
              {failLen}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //渲染 界面DOM 函数
  const initPage = (isDownload) => {
    if (notFinished && !isInner) {
      return (
        <div
          style={{
            ...styleObj?.current["verificationReportContainer"],
          }}
        >
          <div style={styleObj?.current["verificationReportTitleBox"]}>
            风险核查报告
          </div>
          <div className="verificationReportLoadingBox">
            <Result
              icon={<img src={hczPng} />}
              subTitle={
                <div className="verificationReportLoadingText">
                  正在核查中...
                  <span
                    onClick={() => {
                      navigate(`/company/${id}`);
                    }}
                  >
                    返回首页
                  </span>
                </div>
              }
            />
          </div>
        </div>
      );
    }
    return (
      <div
        style={{
          ...styleObj?.current["verificationReportContainer"],
          height: isInner ? "100%" : "auto",
        }}
      >
        {!isInner && (
          <div style={styleObj?.current["verificationReportTitleBox"]}>
            风险核查报告
            {/* {!isDownload &&
              (downloadLoading ? (
                <LoadingOutlined
                  style={styleObj?.current["verificationReportDownloadIcon"]}
                />
              ) : (
                <VerticalAlignBottomOutlined
                  onClick={() => {
                    downloadFunc();
                  }}
                  style={styleObj?.current["verificationReportDownloadIcon"]}
                  className="hoverDownloadIcon"
                />
              ))} */}
          </div>
        )}

        {!isDownload &&
          (isInner ? (
            <div style={styleObj?.current["dateBox"]}>
              <span
                style={{
                  ...styleObj?.current["dateSpan"],
                  display: "flex",
                  alignItems: "center",
                }}
              >
                核查时间 {verificationTime || "-"}{" "}
                {!isDownload &&
                  projectInfo?.project_permission_urls?.includes(
                    "CDD_PRJ_CDD_ITEM_EDIT"
                  ) && (
                    <Button
                      type="primary"
                      style={{ marginLeft: "10px" }}
                      className="cxhcBtn"
                      onClick={reCheckRisk}
                    >
                      重新核查
                    </Button>
                  )}
              </span>
              <span style={styleObj?.current["dateSpan"]}>
                核查项{" "}
                <span style={styleObj?.current["numSpan"]}>
                  {configList?.reduce((a, b) => a + b?.children?.length, 0)}
                </span>{" "}
                条{" "}
                {/* {!isDownload && (
                <SettingOutlined
                  style={{
                    fontSize: "16px",
                    color: "#0068B2",
                    marginLeft: "6px",
                  }}
                />
              )} */}
              </span>
            </div>
          ) : (
            <div
              style={{
                ...styleObj?.current["dateBox"],
                justifyContent: "flex-end",
              }}
            >
              {projectInfo?.project_permission_urls?.includes(
                "CDD_PRJ_CDD_ITEM_EDIT"
              ) && (
                <>
                  <img src={cxhcIcon} alt="" srcset="" className="btnIcon" />
                  <span
                    onClick={reCheckRisk}
                    style={styleObj?.current["btnFloatSpan"]}
                  >
                    重新核查
                  </span>
                </>
              )}
              {downloadLoading ? (
                <Tooltip
                  placement="topLeft"
                  title={"风险核查报告生成时间较长，请耐心等待"}
                  getPopupContainer={(node) => node.parentNode}
                  open={true}
                  arrowPointAtCenter
                  color="#FE545F"
                  overlayStyle={{ height: "30px" }}
                  overlayClassName="sharesTip"
                >
                  <LoadingOutlined className="btnIcon"></LoadingOutlined>
                  <span style={styleObj?.current["btnFloatSpan"]}>
                    下载报告
                  </span>
                </Tooltip>
              ) : (
                <>
                  <img src={xzIcon} alt="" srcset="" className="btnIcon" />
                  <span
                    style={styleObj?.current["btnFloatSpan"]}
                    onClick={() => {
                      downloadFunc();
                    }}
                  >
                    下载报告
                  </span>
                </>
              )}
            </div>
          ))}

        {!isInner && (
          <div
            style={{
              ...styleObj?.current["errorStatusTextBox"],
              margin: "20px 0",
            }}
          >
            <span
              style={{
                ...styleObj?.current["errorStatusTextSpan"],
                color: "#000000",
              }}
            >
              <span
                style={{
                  ...styleObj?.current["errorStatusTextSpanLine"],
                  display: isInner ? "none" : "inline-block",
                  background:
                    "linear-gradient( 223deg, #006BB3 0%, #15D2D6 100%)",
                }}
              ></span>
              概述
            </span>
          </div>
        )}

        {!isInner && (
          <table style={styleObj?.current["summaryTable"]}>
            <tbody style={styleObj?.current["summaryTableBox"]}>
              <tr style={styleObj?.current["summaryTableTr"]}>
                <td style={styleObj?.current["summaryTableTh"]}>尽调公司</td>
                <td style={styleObj?.current["summaryTableTd"]}>
                  {projectInfo?.company_name}
                </td>
              </tr>
              <tr style={styleObj?.current["summaryTableTr"]}>
                <td style={styleObj?.current["summaryTableTh"]}>核查时间</td>
                <td style={styleObj?.current["summaryTableTd"]}>
                  {verificationTime || "-"}
                </td>
              </tr>
              <tr
                style={{
                  ...styleObj?.current["summaryTableTr"],
                  minHeight: "90px",
                  display: "flex",
                }}
              >
                <td
                  style={{
                    ...styleObj?.current["summaryTableTh"],
                    minHeight: "90px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  核查项
                </td>
                <td
                  style={{
                    ...styleObj?.current["summaryTableTd"],
                    minHeight: "90px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {getRiskTaskList()?.length}项
                </td>
              </tr>
              <tr style={styleObj?.current["summaryTableTr"]}>
                <tr
                  style={{
                    ...styleObj?.current["summaryTableTr"],
                    width: "100%",
                    display: "block",
                  }}
                >
                  <td style={styleObj?.current["summaryTableTh"]}>异常项</td>
                  <td style={styleObj?.current["summaryTableTd"]}>
                    {
                      getRiskTaskList()?.filter((_) => _?.risk_res === 1)
                        ?.length
                    }
                    项
                  </td>
                </tr>
                <tr
                  style={{
                    ...styleObj?.current["summaryTableTr"],
                    width: "100%",
                    display: "block",
                  }}
                >
                  <td style={styleObj?.current["summaryTableTh"]}>正常项</td>
                  <td style={styleObj?.current["summaryTableTd"]}>
                    {
                      getRiskTaskList()?.filter(
                        (_) =>
                          _?.risk_res !== 1 &&
                          _?.risk_res !== 2 &&
                          _?.risk_res !== 10
                      )?.length
                    }
                    项
                  </td>
                </tr>
                <tr
                  style={{
                    ...styleObj?.current["summaryTableTr"],
                    width: "100%",
                    display: "block",
                  }}
                >
                  <td style={styleObj?.current["summaryTableTh"]}>失败项</td>
                  <td style={styleObj?.current["summaryTableTd"]}>
                    {
                      getRiskTaskList()?.filter(
                        (_) => _?.risk_res === 2 || _?.risk_res === 10
                      )?.length
                    }
                    项
                  </td>
                </tr>
              </tr>
            </tbody>
          </table>
        )}

        {getRiskTaskList()?.some(
          (item) =>
            item?.risk_res === 1 ||
            item?.risk_res === 2 ||
            item?.risk_res === 10
        ) ? (
          <div style={styleObj?.current["errorStatusTextBox"]}>
            <span style={styleObj?.current["errorStatusTextSpan"]}>
              <span
                style={{
                  ...styleObj?.current["errorStatusTextSpanLine"],
                  display: isInner ? "none" : "inline-block",
                }}
              ></span>
              异常结果
            </span>
            {isInner && (
              <span
                style={styleObj?.current["showCompleteReportSpan"]}
                onClick={() => {
                  window.open(
                    window.location?.origin +
                      `/company/${id}/verificationReport`,
                    guid()
                  );
                }}
              >
                <FileSearchOutlined />
                查看完整报告
              </span>
            )}
          </div>
        ) : isInner ? (
          initAllAdoptPage(riskTaskList)
        ) : null}

        <div
          style={{
            ...styleObj?.current["reportDetailBox"],
            paddingLeft: isInner ? "0px" : "10px",
          }}
        >
          {initDomFunc(riskTaskList, isDownload)}
        </div>

        {!isInner && (
          <div
            style={{
              ...styleObj?.current["errorStatusTextBox"],
              margin: "20px 0",
            }}
          >
            <span
              style={{
                ...styleObj?.current["errorStatusTextSpan"],
                color: "#000000",
              }}
            >
              <span
                style={{
                  ...styleObj?.current["errorStatusTextSpanLine"],
                  display: isInner ? "none" : "inline-block",
                  background:
                    "linear-gradient( 223deg, #006BB3 0%, #15D2D6 100%)",
                }}
              ></span>
              全部核查结果
            </span>
          </div>
        )}

        {!isInner && (
          <div style={{ paddingLeft: isInner ? "0px" : "10px" }}>
            {initAllDetailTableDom(riskTaskList, isDownload)}
          </div>
        )}

        <CaseDetailsDrawer
          title="司法案件详情"
          visible={caseDrawerVisible}
          caseId={caseDetailInfo.case_id}
          onCancel={() => {
            setCaseDrawerVisible(false);
          }}
        ></CaseDetailsDrawer>

        <DetailsDrawer
          title={choiceItem?.typeName + "详情"}
          visible={detailsDrawerVisible}
          dataTable={choiceItem?.tableName}
          item={choiceItem}
          typeName={choiceItem?.typeName}
          onCancel={() => {
            setDetailsDrawerVisible(false);
          }}
        ></DetailsDrawer>

        <Modal
          // title="详情"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          width={800}
          centered={true}
          zIndex={88}
          footer={null}
          title={null}
          wrapClassName="verificationReportModal"
        >
          {modalType === "table" && (
            <Table
              pagination={tableData?.length > 10}
              scroll={{ y: "65vh" }}
              columns={tableColumns}
              dataSource={tableData}
              className="myFormTable"
              bordered={true}
            />
          )}
          {modalType === "article" && (
            <div className="articleTitleTextContent">
              <div className="iconBox">
                <Tooltip
                  placement="top"
                  title={copySuccess ? "复制成功" : "复制"}
                  arrowPointAtCenter
                >
                  {copySuccess ? (
                    <CheckOutlined className="copySuccessIcon" />
                  ) : (
                    <CopyOutlined
                      className="copyIcon"
                      onClick={() => {
                        selectElementContents(
                          document.getElementById("copyDom")
                        );
                      }}
                    />
                  )}
                </Tooltip>
              </div>
              <div style={{ padding: "10px" }} id="copyDom">
                {(historicalEvolutionData || []).map((item) => (
                  <div className="titleTextItem">
                    <div className="titleTextItemTitle">
                      {item[rowItemRule?.title_key] ?? ""}
                    </div>
                    <div className="titleTextItemText">
                      {item[rowItemRule?.text_key] ?? ""}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Modal>
      </div>
    );
  };
  if (isInner) {
    return pageLoading ? (
      <MySpin></MySpin>
    ) : (
      <Scrollbars
        autoHide={true}
        autoHideTimeout={5000}
        style={{ height: "100%", width: "100%" }}
      >
        {initPage()}
      </Scrollbars>
    );
  }
  return (
    <div style={styleObj?.current["verificationReportPageContent"]}>
      {pageLoading ? (
        <div
          style={{
            width: "100%",
            height: "50px",
            position: "absolute",
            top: "calc(50% - 25px)",
            left: "0px",
          }}
        >
          <MySpin></MySpin>
        </div>
      ) : (
        initPage()
      )}
    </div>
  );
};
export default VerificationReport;
