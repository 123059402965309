import "./index.scss";
import { useState, useEffect, useRef, useMemo } from "react";
import { useGetState } from "ahooks";
import bondApi from "../../../../apis/bond/index";
import {
  Button,
  Pagination,
  Typography,
  Tooltip,
  message,
  Select,
  Input,
  AutoComplete,
} from "antd";
import MySpin from "../../../../components/MySpin";
import MyEmpty from "../../../../components/Empty";
import MyCard from "../../../../components/MyCard";
import {
  PlusCircleOutlined,
  PlusCircleFilled,
  DeleteOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";
import UploadFileDrawer from "../../../../components/UploadFileDrawer";
import UploadModal from "../UploadMadel";
import { getLicenseValid } from "../../../../unit/unit";
import { ReactSVG } from "react-svg";
import deleteIcon from "../../../../assets/delete2.svg";
import debounce from "lodash/debounce";

const { Paragraph } = Typography;

const BondAddPage = (props) => {
  const { addSuccessCallBack } = props;
  const [pageNumber, setPageNumber, getPageNumber] = useGetState(1);
  const [pageSize, setPageSize, getPageSize] = useGetState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [data2, setData2, getData2] = useGetState([]);

  const [loading2, setLoading2] = useState(false);
  const [downLoading, setDownLoading] = useState(false);

  const [searchLoading, setSearchLoading] = useState(false);
  const [issuerType, setIssuerType, getIssuerType] = useGetState(null);
  const [issuerKey, setIssuerKey, getIssuerKey] = useGetState(null);
  const [issuerTypeOptions, setIssuerTypeOptions, getIssuerTypeOptions] =
    useGetState([]);
  const [underwritersKey, setUnderwritersKey, getUnderwritersKey] =
    useGetState(null);
  const [
    underwritersTypeOptions,
    setUnderwritersTypeOptions,
    getUnderwritersTypeOptions,
  ] = useGetState([]);

  const [upLoading, setUpLoading] = useState(false);
  const [fileVisible, setFileVisible] = useState(false);
  const [upData, setUpData] = useState(null);
  const [uploadVisible, setUploadVisible] = useState(false);

  const [issuerListLength, setIssuerListLength] = useState(0);
  const [issuerOptions, setIssuerOptions] = useState([]);
  const fetchRef = useRef(0);

  const inpurRef = useRef(null);
  const columns = useRef([
    {
      label: "统一信用号",
      key: "credit_code",
      width: "65%",
      type: "text",
      format: (data, info) => {
        return (
          <Paragraph
            style={{
              width: `100%`,
              color: "#535353",
            }}
            ellipsis={{
              tooltip: (
                <span style={{ fontSize: "12px" }}>{info?.credit_code}</span>
              ),
            }}
          >
            {info?.credit_code}
          </Paragraph>
        );
      },
    },
    {
      label: "法人",
      key: "legal_person_name",
      width: "35%",
      type: "text",
      format: (data, info) => {
        return (
          <Paragraph
            style={{
              width: `100%`,
              color: "#535353",
            }}
            ellipsis={{
              tooltip: info?.legal_person_name,
            }}
          >
            {info?.legal_person_name}
          </Paragraph>
        );
      },
    },
    {
      label: "主承销商",
      key: "b_agency_names",
      width: "100%",
      type: "text",
      format: (data, info) => {
        return (
          <Paragraph
            style={{
              width: `100%`,
              color: "#535353",
            }}
            ellipsis={{
              tooltip: {
                title: (
                  <div className="bondAddPageParagraphTip">
                    {info?.b_agency_names}
                  </div>
                ),
                overlayClassName: "bondAddPageParagraphTooltip",
              },
            }}
          >
            {info?.b_agency_names}
          </Paragraph>
        );
      },
    },
    {
      label: "债券",
      key: "s_info_names",
      width: "100%",
      type: "text",
      format: (data, info) => {
        return (
          <Paragraph
            style={{
              width: `100%`,
              color: "#535353",
            }}
            ellipsis={{
              tooltip: {
                title: (
                  <div className="bondAddPageParagraphTip">
                    {info?.s_info_names}
                  </div>
                ),
                overlayClassName: "bondAddPageParagraphTooltip",
              },
            }}
          >
            {info?.s_info_names}
          </Paragraph>
        );
      },
    },
  ]);

  const getIssuersListFunc = (noLoading, addOld) => {
    setDownLoading(true);
    const params = {
      page_size: 1,
      page: 1,
    };
    bondApi.getIssuersList(params).then((res) => {
      if (res.code === 200) {
        setIssuerListLength(res.data.total);
      } else {
        setIssuerListLength(0);
      }
      setDownLoading(false);
    });
  };

  const getIssuersDataFunc = (notLoading) => {
    if (!notLoading) {
      setLoading(true);
    }
    const params = {
      page_size: getPageSize(),
      page: getPageNumber(),
      issuer_type: getIssuerType() || null,
      b_agency_name: getUnderwritersKey() || null,
      issuer_key: getIssuerKey() || null,
    };
    bondApi.getIssuers(params).then((res) => {
      if (res.code === 200) {
        setTotal(res.data.total);
        let data = res.data.data;
        data = data?.map((_) => {
          if (getData2().some((__) => __.company_id === _.company_id)) {
            _.is_add = true;
          }
          return _;
        });
        setData(data || []);
      } else {
        setTotal(0);
        setData([]);
      }
      setLoading(false);
    });
  };

  const getIssuersTypeFunc = () => {
    const params = {
      table_name: "bond_issuer",
      group_field: "company_type",
    };
    bondApi.getBondEnums(params).then((res) => {
      if (res.code === 200) {
        const data = (res.data || [])?.map((_) => ({
          label: _.name,
          value: _.val,
        }));
        setIssuerTypeOptions(data);
      } else {
        setIssuerTypeOptions([]);
      }
    });
  };

  const getUnderwritersTypeFunc = () => {
    const params = {
      table_name: "bond_info_agency",
      group_field: "b_agency_name",
    };
    bondApi.getBondEnums(params).then((res) => {
      if (res.code === 200) {
        const data = (res.data || [])?.map((_) => ({
          label: _.name,
          value: _.val,
        }));
        setUnderwritersTypeOptions(data);
      } else {
        setUnderwritersTypeOptions([]);
      }
    });
  };

  useEffect(() => {
    getIssuersListFunc();
    getIssuersDataFunc();
    getIssuersTypeFunc();
    getUnderwritersTypeFunc();
  }, []);

  const goInfo = (item) => {
    // let url = window.location.origin + "/bond/" + item.company_id;
    // window.open(url, "bond");
  };

  const deleteItem = (item) => {
    setData((prev) => {
      prev = prev.map((_) => {
        if (item.company_id === _.company_id) {
          _.is_add = false;
        }
        return _;
      });
      return prev;
    });
    setData2((prev) => {
      prev = prev.filter((_) => {
        return item.company_id !== _.company_id;
      });
      return prev;
    });
  };

  const addIssuersFunc = (list) => {
    setData((prev) => {
      prev = prev.map((_) => {
        if (list?.some((__) => _.company_id === __.company_id)) {
          _.is_add = true;
        }
        return _;
      });
      return prev;
    });
    setData2((prev) => {
      return [...prev, ...list];
    });
  };

  const addFunc = () => {
    setLoading2(true);
    const list = getData2()?.map((_) => _.company_id);
    bondApi.addIssuers(list).then((res) => {
      if (res.code === 200) {
        message.success(res.message);
        addSuccessCallBack();
      } else {
        message.error(res.message);
      }
      setLoading2(false);
    });
  };

  const pageChange = (pageNumber) => {
    setPageNumber(pageNumber);
    setTimeout(() => {
      getIssuersDataFunc();
    }, 100);
  };

  const updatePageSize = (pageSize) => {
    setPageNumber(1);
    setPageSize(pageSize);
    setTimeout(() => {
      getIssuersDataFunc();
    }, 100);
  };

  //上传成功回调
  const uploadFileSuccess = (files) => {
    setUpLoading(true);
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("file[]", file?.originFileObj);
    });
    bondApi.uploadCheckFiles(formData).then((res) => {
      if (res.code === 200) {
        setUpData(res.data);
        setFileVisible(false);
        setUploadVisible(true);
      } else {
        message.error(res.message);
      }
      setUpLoading(false);
    });
  };

  //上传成功点击确定
  const uploadSuccess = () => {
    console.log(upData, "成功");
    setData2((prev) => {
      let arr = prev;
      (upData?.success_data || [])?.forEach((_) => {
        if (!arr?.some((__) => __.company_id === _.company_id)) {
          arr.push(_);
        }
      });
      return [...arr];
    });
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setIssuerOptions([]);
      if (!value) {
        return;
      }
      setSearchLoading(true)
      bondApi.searchIssuers(value).then((res) => {
        setSearchLoading(false)
        const newOptions = res.data;
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setIssuerOptions(
          newOptions?.map((_) => ({
            label: _,
            value: _,
          }))
        );
      });
    };

    return debounce(loadOptions, 800);
  }, [issuerOptions]);

  const onSelect = (data) => {
    setIssuerKey(data);
    setIssuerOptions([{
      label: data,
      value: data
    }])
  };
  const onChange = (data) => {
    setIssuerKey(data?.trim());
  };

  return (
    <div className="bondAddPageContent">
      <div className="bondAddPageBox">
        <div className="leftBondAddPageContent">
          <div className="tipBox">
            {downLoading
              ? null
              : issuerListLength === 0
              ? "你还没有关注任何发行人，请选择关注的发行人"
              : "请选择关注的发行人"}
          </div>
          <div className="searchBox">
            <div className="searchItemBox">
              <span className="searchLabel">发行人分类</span>
              <Select
                style={{
                  width: "200px",
                  borderBottom: "1px solid rgba(0,0,0,0.08)",
                  height: "27px",
                }}
                value={issuerType}
                onChange={(value) => {
                  setIssuerType(value);
                  setPageNumber(1);
                  setTimeout(() => {
                    getIssuersDataFunc();
                  }, 100);
                }}
                allowClear
                bordered={false}
                options={issuerTypeOptions}
                placeholder="全部"
              />
              <span className="searchLabel">主承销商</span>
              <Select
                style={{
                  width: "230px",
                  borderBottom: "1px solid rgba(0,0,0,0.08)",
                  height: "27px",
                }}
                showSearch
                value={underwritersKey}
                onChange={(value) => {
                  setUnderwritersKey(value);
                  setPageNumber(1);
                  setTimeout(() => {
                    getIssuersDataFunc();
                  }, 100);
                }}
                dropdownMatchSelectWidth={220}
                allowClear
                bordered={false}
                options={underwritersTypeOptions}
                placeholder="全部"
              />
              <span className="searchLabel">发行人/债券</span>
              <AutoComplete
                options={issuerOptions}
                style={{
                  width: "230px",
                  borderBottom: "1px solid rgba(0,0,0,0.08)",
                }}
                bordered={false}
                allowClear
                onSelect={onSelect}
                onSearch={debounceFetcher}
                onChange={onChange}
                value={issuerKey}
                ref={inpurRef}
                onClear={() => {
                  setIssuerKey(null);
                  setIssuerOptions([]);
                  inpurRef.current.focus();
                  // setPageNumber(1);
                  // setTimeout(() => {
                  //   getIssuersDataFunc();
                  // }, 100);
                }}
                notFoundContent={
                  // issuerKey ? (
                  //   searchLoading ? (
                  //     <MySpin></MySpin>
                  //   ) : (
                  //     <MyEmpty></MyEmpty>
                  //   )
                  // ) : null
                  null
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setPageNumber(1);
                    inpurRef.current.blur();
                    setTimeout(() => {
                      getIssuersDataFunc();
                    }, 100);
                  }
                  e.stopPropagation();
                }}
                onBlur={() => {
                  setPageNumber(1);
                  setTimeout(() => {
                    getIssuersDataFunc();
                  }, 100);
                }}
                placeholder="输入发行人名称或债券简称/代码"
              />
              {/* <Input
                style={{
                  width: "230px",
                  borderBottom: "1px solid rgba(0,0,0,0.08)",
                  padding: "2px 4px",
                }}
                allowClear
                bordered={false}
                value={issuerKey}
                ref={inpurRef}
                onChange={(e) => {
                  setIssuerKey(e.target.value?.trim());
                }}
                onPressEnter={() => {
                  setPageNumber(1);
                  inpurRef.current.blur()
                  setTimeout(() => {
                    getIssuersDataFunc();
                  }, 100);
                }}
                onBlur={() => {
                  setPageNumber(1);
                  setTimeout(() => {
                    getIssuersDataFunc();
                  }, 100);
                }}
                placeholder="输入发行人名称或债券简称/代码"
              /> */}
            </div>
            <div
              className="addAllBox"
              onClick={() => {
                addIssuersFunc(data?.filter((_) => !_.is_add));
              }}
            >
              <PlusCircleFilled style={{ marginRight: "5px" }} />
              添加全部
            </div>
          </div>
          <div
            className="bondAddPageInfiniteScrollBox"
            id="bondAddPageInfiniteScrollBox"
          >
            {loading || downLoading ? (
              <MySpin></MySpin>
            ) : data.length > 0 ? (
              <>
                <Scrollbars
                  autoHide={true}
                  autoHideTimeout={5000}
                  style={{
                    height: `calc(100% - ${total > 10 ? 60 : 10}px)`,
                    width: "100%",
                    marginTop: "10px",
                  }}
                >
                  <div className="cardBox">
                    {data.map((item, index) => (
                      <div className="cardItem" key={item.company_id}>
                        <MyCard
                          rightTopIcon={
                            item.is_add ? (
                              <Tooltip
                                placement="top"
                                title={
                                  data2?.some(
                                    (_) => _.company_id === item.company_id
                                  )
                                    ? "已选择"
                                    : "已关注"
                                }
                                arrowPointAtCenter
                              >
                                <PlusCircleOutlined className="disAddIcon" />
                              </Tooltip>
                            ) : (
                              <Tooltip
                                placement="top"
                                title="添加关注"
                                arrowPointAtCenter
                              >
                                <PlusCircleFilled
                                  className="addIcon"
                                  onClick={() => {
                                    addIssuersFunc([item]);
                                  }}
                                />
                              </Tooltip>
                            )
                          }
                          pic={item.logo_url}
                          title={item.company_name}
                          showCollection={false}
                          imgClick={goInfo}
                          cardData={item}
                          columns={columns.current}
                          width="100%"
                          unClick={true}
                        ></MyCard>
                      </div>
                    ))}
                  </div>
                </Scrollbars>
                {total > 10 ? (
                  <div className="paginationBox">
                    <Pagination
                      className="myPagination"
                      current={pageNumber}
                      total={total}
                      pageSize={pageSize}
                      pageSizeOptions={[10, 20, 50, 100]}
                      onChange={pageChange}
                      showTotal={(total) => `共 ${total} 条`}
                      showSizeChanger
                      // getPopupContainer={() => document.body}
                      selectComponentClass={() => {
                        return (
                          <Select
                            defaultValue={pageSize}
                            options={[
                              {
                                label: "10条/页",
                                value: 10,
                              },
                              {
                                label: "20条/页",
                                value: 20,
                              },
                              {
                                label: "50条/页",
                                value: 50,
                              },
                              {
                                label: "100条/页",
                                value: 100,
                              },
                            ]}
                            onChange={updatePageSize}
                          ></Select>
                        );
                      }}
                      // showQuickJumper
                    />
                  </div>
                ) : null}
              </>
            ) : (
              <div className="emptyBox">
                <MyEmpty></MyEmpty>
              </div>
            )}
          </div>
        </div>

        <div className="rightBondAddPageContent">
          <div className="tipBox">
            <div>
              已选择的发行人<span className="number">{data2?.length}</span>家
            </div>
            <Tooltip placement="top" title="上传" arrowPointAtCenter>
              <VerticalAlignTopOutlined
                onClick={() => {
                  setFileVisible(true);
                }}
              />
            </Tooltip>
          </div>
          <Scrollbars
            autoHide={true}
            autoHideTimeout={5000}
            style={{
              height: "calc(100% - 45px)",
              width: "100%",
              marginTop: "10px",
            }}
          >
            {data2?.length > 0 ? (
              <div className="IssuerListBox">
                {data2?.map((item) => (
                  <div className="IssuerCard">
                    <div
                      className="companyNameBox"
                      onClick={() => {
                        goInfo(item);
                      }}
                    >
                      {item?.company_name}
                    </div>
                    <div className="companyInfoBox">
                      <div className="infoSpan">
                        <div>
                          <span>统一信用号</span>
                          <Paragraph
                            style={{
                              width: `calc(100% - 65px)`,
                              maxWidth: "160px",
                              color: "#535353",
                              marginBottom: "0",
                            }}
                            ellipsis={{
                              tooltip: (
                                <span style={{ fontSize: "12px" }}>
                                  {item.credit_code}
                                </span>
                              ),
                            }}
                          >
                            {item.credit_code}
                          </Paragraph>
                        </div>
                        <div>
                          <span>法人</span>
                          <Paragraph
                            style={{
                              // width: `calc(100% - 65px)`,
                              maxWidth: "120px",
                              color: "#535353",
                              marginBottom: "0",
                            }}
                            ellipsis={{
                              tooltip: (
                                <span style={{ fontSize: "12px" }}>
                                  {item.legal_person_name}
                                </span>
                              ),
                            }}
                          >
                            {item.legal_person_name}
                          </Paragraph>
                          {/* {item.legal_person_name} */}
                        </div>
                      </div>
                      <Tooltip
                        placement="top"
                        title="取消关注"
                        arrowPointAtCenter
                      >
                        {/* <DeleteOutlined
                          className="deleteIcon"
                          onClick={() => {
                            deleteItem(item);
                          }}
                        /> */}
                        <ReactSVG
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteItem(item);
                          }}
                          className="deleteIcon hoverIcon"
                          src={deleteIcon}
                        ></ReactSVG>
                      </Tooltip>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="emptyBox">
                <MyEmpty></MyEmpty>
              </div>
            )}
          </Scrollbars>
        </div>
      </div>
      <div>
        {issuerListLength !== 0 && (
          <Button
            // type="primary"
            style={{ width: "110px", marginRight: "20px" }}
            onClick={() => {
              addSuccessCallBack();
            }}
            loading={loading2}
          >
            取消
          </Button>
        )}
        <Button
          type="primary"
          style={{ width: "110px" }}
          onClick={() => {
            getLicenseValid(false, true).then((res) => {
              if (res) {
                if (getData2()?.length === 0) {
                  message.warning("请先添加发行人!");
                  return;
                }
                addFunc();
              }
            });
          }}
          disabled={getData2()?.length === 0}
          loading={loading2}
        >
          确定
        </Button>
      </div>

      <UploadFileDrawer
        loading={upLoading}
        visible={fileVisible}
        onClose={() => setFileVisible(false)}
        successFunc={uploadFileSuccess}
        title="上传发行人"
        // multiple={true}
      ></UploadFileDrawer>

      <UploadModal
        upData={upData}
        visible={uploadVisible}
        onCancel={() => {
          setUploadVisible(false);
        }}
        onOk={() => {
          setUploadVisible(false);
          uploadSuccess();
        }}
      ></UploadModal>
    </div>
  );
};
export default BondAddPage;
