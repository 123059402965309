import "./index.scss";
import { useParams } from "react-router-dom";
import MyTable from "../../../components/MyTable";
import { useEffect, useRef, useState } from "react";
import CommentsHeaderTitle from "../../../components/CommentsHeaderTitle";
import Scrollbars from "react-custom-scrollbars";
import MySpin from "../../../components/MySpin";
import {
  Select,
  Tag,
  Checkbox,
  message,
  Pagination,
  Image,
  Button,
  Typography,
  Modal,
  Avatar,
  Tooltip,
} from "antd";
import { useGetState } from "ahooks";
import { ArrayIsEqual } from "../../../unit/unit";
import projectApi from "../../../apis/project";
import { useSelector } from "react-redux";
import MyEmpty from "../../../components/Empty";
import moment from "moment";
import { SwapLeftOutlined } from "@ant-design/icons";
import { showInfoFunc } from "../../../components/MyTable";
import InfoIcon from "../../../components/Icons/InfoIcon";
import CommentsIcon from "../../../components/Icons/CommentsIcon";
import bg from "../../../assets/imgBg.png";

import img1 from "../../../assets/司法.svg";
import img2 from "../../../assets/重大事件.svg";
import img3 from "../../../assets/工商信息2.svg";
import img4 from "../../../assets/评级.svg";
import img5 from "../../../assets/债券相关事件.svg";

const { Option } = Select;
const { Paragraph } = Typography;

const AnnouncementPublicOpinionCard = (props) => {
  const { info, getData, dataTable, typeName } = props;
  const { id } = useParams();

  const [visible, setVisible] = useState(false);
  const [showContent, setShowContent] = useState("");
  const [showImgList, setShowImgList] = useState([]);

  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  const colorRef = useRef({
    债券相关事件: "#48DBD7",
    评级: "#FEC054",
    工商信息: "#AAD461",
    重大事件: "#C6C0FC",
    司法: "#6CAEFF",
  });
  const iconRef = useRef({
    债券相关事件: img5,
    评级: img4,
    工商信息: img3,
    重大事件: img2,
    司法: img1,
  });

   //
   const lookUp = (val, info) => {
    setShowContent(val);
    setShowImgList(info?.images?.val || []);
    setVisible(true);
  };

  return (
    <div className="publicSentimentCard">
      <div
        className="imgBox"
        style={{ background: colorRef.current[info?.event_type_desc?.val] }}
      >
        <img src={bg} className="imgBg" />
        <img
          src={iconRef.current[info?.event_type_desc?.val] || null}
          alt={info?.event_type_desc?.val}
          className="iconImg"
        />
        <div className="typeBox">{info?.anc_type?.val}</div>
      </div>

      <div className="publicSentimentCardContent">
        <a
          className="cardTitle"
          href={info?.screenshots_url?.val}
          target="_black"
        >
          {info?.anc_title?.val}
        </a>

        <div style={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
          <div className="contentBoxTitle">
            {info?.anc_org_name?.val}
            <div className="tagsBox">
              {info?.relatedPartiesType?.val?.map((tag) => (
                <Tooltip
                  key={Math.random()}
                  placement="top"
                  title={tag.full_name}
                  arrowPointAtCenter
                >
                  <div>
                    <Tag key={Math.random()}>{tag?.short_name}</Tag>
                  </div>
                </Tooltip>
              ))}
            </div>
          </div>
          <div className="contentBox_">
            <div className="labelBox">
              <span>来源</span> {info?.data_source_std?.val}
              <span style={{ marginLeft: "16px" }}>发布时间</span>
              {info?.anc_date?.val}
            </div>
          </div>
          <Paragraph
              className="cardText"
              ellipsis={{
                rows: 2,
                expandable: true,
                symbol: (
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      lookUp(info?.contentHtml?.val, info);
                    }}
                  >
                    更多
                  </span>
                ),
              }}
            >
              {info?.content?.val}
            </Paragraph>
        </div>
      </div>

      <div className="iconBox">
        {projectInfo?.project_permission_urls.includes(
          "CDD_PRJ_COMMENT_VIEW"
        ) && (
          <InfoIcon
            getData={() => {
              getData(true);
            }}
            info={{
              id: info.id?.val,
              comments: info.comments,
            }}
            mode={20}
            dataInfo={info}
            dataTable={dataTable}
            typeName={typeName}
            projectId={projectInfo.id}
          />
        )}

        {projectInfo?.project_permission_urls.includes("CDD_PRJ_COMMENT") ? (
          <CommentsIcon
            getData={() => {
              getData(true);
            }}
            info={{
              id: info.id?.val,
            }}
            mode={20}
            dataInfo={info}
            dataTable={dataTable}
            typeName={typeName}
            projectId={projectInfo.id}
          />
        ) : null}
      </div>

      <Modal
        title="详情"
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        //   onOk={handleOk}
        wrapClassName="publicSentimentBoxModal"
        centered
        footer={null}
      >
        <div className="showContentBox" key={Math.random()}>
          <span
            dangerouslySetInnerHTML={{
              __html: showContent,
            }}
          ></span>
          <div className="imgBox">
            {showImgList.map((img) => (
              <Image height={160} src={img} rootClassName="showContentImg" />
            ))}
          </div>
        </div>
      </Modal>

    </div>
  );
};
const AnnouncementPublicOpinion = (props) => {
  const dataTable = "company_notice_opinion";
  const typeName = "公告舆情";
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [updateTime, setUpdateTime] = useState(null);
  const [publicOpinionList, setPublicOpinionList] = useState([]);

  const [checkAll, setCheckAll] = useState(false);
  const [checkAllIndeterminate, setCheckAllIndeterminate] = useState(true);
  const [subjectScopeList, setSubjectScopeList, getSubjectScopeList] =
    useGetState([
      "CDD_SUB_DD_CORP",
      "CDD_SUB_CTRL_SUB_CORP",
      "CDD_SUB_CTRL_SHRHLDR",
    ]);
  const [subjectScopeOptions, setSubjectScopeOptions] = useState([]);
  const [oldSubjectScopeList, setOldSubjectScopeList, getOldSubjectScopeList] =
    useGetState([]);
  const [relatedPartiesCountObj, setRelatedPartiesCountObj] = useState({});

  const [pageNumber, setPageNumber, getPageNumber] = useGetState(1);
  const [pageSize, setPageSize, getPageSize] = useGetState(10);
  const [total, setTotal] = useState(0);

  const [options, setOptions] = useState({});
  const [numberOptions, setNumberOptions] = useState({});

  const [typeCheckAll, setTypeCheckAll] = useState(false);
  const [typeCheckAllIndeterminate, setTypeCheckAllIndeterminate] =
    useState(false);
  const [typeList, setTypeList, getTypeList] = useGetState([]);
  const [oldTypeList, setOldTypeList, getOldTypeList] = useGetState([]);

  const [eventCheckAll, setEventCheckAll] = useState(false);
  const [eventCheckAllIndeterminate, setEventCheckAllIndeterminate] =
    useState(false);
  const [eventList, setEventList, getEventList] = useGetState([]);
  const [oldEventList, setOldEventList, getOldEventList] = useGetState([]);

  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};

  const [selectMenuLoading, setSelectMenuLoading] = useState(false);
  //转成第一页 获取数据
  const getOnePageData = async (flag) => {
    await setPageNumber(1);
    await setTimeout(() => {
      getData(flag);
    }, 100);
  };

  //多选选择框tag选项样式
  const tagRender = (props) => {
    const { label, value, closable } = props;
    const onClose = async () => {
      await setSubjectScopeList((prev) => {
        prev = prev.filter((_) => _ !== value);
        if (prev.length === 0) {
          setCheckAll(false);
          setCheckAllIndeterminate(false);
        }
        return [...prev];
      });
      await getData(true);
    };
    return (
      <Tag
        // onClose={onClose}
        // closable={closable}
        style={{
          marginRight: 3,
        }}
      >
        {label?.at(0)}
      </Tag>
    );
  };

  //获取关联方对应的数量
  const getCodeNumber = () => {
    projectApi
      .getOptionsNumber(id, {
        name: dataTable,
        companyId: projectInfo.company_id,
      })
      .then((res) => {
        if (res.code === 200) {
          setRelatedPartiesCountObj(res.data);
        } else {
          setRelatedPartiesCountObj({});
        }
      });
  };

  //获取code枚举属性
  const getCodeInfo = () => {
    projectApi.getDicCodeInfo("CDD_SUB").then((res) => {
      if (res.code === 200) {
        setSubjectScopeOptions(res.data);
        // getCodeNumber();
      } else {
        setSubjectScopeOptions([]);
      }
    });
  };

  //获取 公告类型和事件主题选项
  const getEventTypeAndSubject = () => {
    projectApi.getEventTypeAndSubject(id).then((res) => {
      if (res.code === 200) {
        setOptions(res.data);
      } else {
        setOptions(res.data);
      }
    });
  };

  //获取 搜索条件数量
  const getConditionStatistics = (type) => {
    setSelectMenuLoading(true);
    const params = {
      sub_type: getSubjectScopeList(),
      event_type: getTypeList(),
      event_subject: getEventList(),
    };
    if (type === "主体范围") {
      delete params.sub_type;
    }
    if (type === "公告类型") {
      delete params.event_type;
    }
    if (type === "公告主体") {
      delete params.event_subject;
    }
    projectApi.getConditionStatistics(id, params).then((res) => {
      if (res.code === 200) {
        setNumberOptions(res.data);
      } else {
        setNumberOptions({});
        message.error(res.message);
      }
      setSelectMenuLoading(false);
    });
  };

  useEffect(() => {
    getCodeInfo();
    getEventTypeAndSubject();
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    const params = {
      page_size: getPageSize(),
      page_number: getPageNumber(),
    };
    if (getSubjectScopeList().length > 0) {
      params.sub_type = JSON.stringify(getSubjectScopeList());
    }
    const filterArr = [];
    if (getTypeList().length > 0) {
      filterArr.push({
        key: "anc_type",
        values: getTypeList(),
      });
    }
    if (getEventList().length > 0) {
      filterArr.push({
        key: "anc_org_name",
        values: getEventList(),
      });
    }
    if (filterArr?.length > 0) {
      params.select_filed_list = filterArr;
    }
    projectApi.getDataInfo(dataTable, id, params).then((res) => {
      console.log(
        `查看 ${typeName} 数据,表名为 ${dataTable},第 ${
          getPageSize() * (getPageNumber() - 1)
        } -  ${getPageSize() * getPageNumber()} 条`,
        "参数为:",
        params,
        "结果为:",
        res
      );
      if (res.code === 200) {
        setTotal(res.data.total);
        setUpdateTime(res.data.data_update_time);
        setPublicOpinionList(res.data.data);
      } else {
        message.error(res.message);
        setTotal(0);
        setUpdateTime(null);
        setPublicOpinionList([]);
      }
      setLoading(false);
    });
  };

  //页码变化
  const onChange = async (page, pageSize) => {
    await setPageSize(pageSize);
    await setPageNumber(page);
    await getData(true);
  };

  return (
    <div className="AnnouncementPublicOpinionBox">
      <div className="infoBox">
        <CommentsHeaderTitle
          title={typeName}
          dataTable={dataTable}
          typeName={typeName}
        ></CommentsHeaderTitle>
        <Scrollbars
          autoHide={true}
          autoHideTimeout={5000}
          style={{
            height: "calc(100% - 20px)",
            width: "100%",
          }}
        >
          {loading ? (
            <MySpin></MySpin>
          ) : (
            <div className="tableBox">
              <div className="dateBox">
                <div className="dataInput">
                  更新时间：{updateTime || "-"}
                  <div className="inputBox">
                    <div className="label">主体范围</div>
                    <Select
                      mode="multiple"
                      value={subjectScopeList}
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="全部"
                      showArrow={true}
                      bordered={false}
                      maxTagCount="responsive"
                      className="subjectScopeSelect"
                      tagRender={tagRender}
                      filterOption={(inputValue, option) =>
                        option.children?.at(0)?.props?.title?.includes(inputValue)
                      }
                      onChange={async (value) => {
                        if (value.length === subjectScopeOptions.length) {
                          await setCheckAll(true);
                          await setCheckAllIndeterminate(false);
                        } else {
                          await setCheckAllIndeterminate(true);
                          await setCheckAll(false);
                        }
                        if (value.length === 0) {
                          await setCheckAllIndeterminate(false);
                        }
                        await setSubjectScopeList(value);
                        // getData(true);
                      }}
                      onFocus={() => {
                        setOldSubjectScopeList(getSubjectScopeList());
                        getConditionStatistics("主体范围");
                      }}
                      onBlur={() => {
                        if (
                          !ArrayIsEqual(
                            getOldSubjectScopeList(),
                            getSubjectScopeList()
                          )
                        ) {
                          getOnePageData(true);
                        }
                      }}
                      onClear={async () => {
                        setCheckAll(false);
                        setCheckAllIndeterminate(false);
                        await setSubjectScopeList([]);
                        await getOnePageData(true);
                      }}
                      dropdownRender={(menu) =>
                        selectMenuLoading ? (
                          <MySpin></MySpin>
                        ) : (
                          <div className="subjectScopeSearchPoP">
                            <Checkbox
                              style={{ margin: "5px", marginLeft: "12px" }}
                              indeterminate={checkAllIndeterminate}
                              checked={checkAll}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSubjectScopeList(
                                    subjectScopeOptions.map(
                                      (_) => _.dic_item_code
                                    )
                                  );
                                } else {
                                  setSubjectScopeList([]);
                                }
                                setCheckAll(e.target.checked);
                                setCheckAllIndeterminate(false);
                                e.stopPropagation();
                              }}
                            >
                              全选
                            </Checkbox>
                            {menu}
                          </div>
                        )
                      }
                    >
                      {subjectScopeOptions.map((item, index) => (
                        <Option
                          key={item.dic_item_code}
                          value={item.dic_item_code}
                        >
                          <a className="textItem" title={item.dic_item_name}>
                            {index + ". " + item.dic_item_name}
                          </a>{" "}
                          <Tag
                            style={
                              !(numberOptions?.sub_type || {})[
                                item.dic_item_code
                              ]
                                ? {
                                    color: "#535353",
                                    background: "#EFEFEF",
                                    borderColor: "#EFEFEF",
                                  }
                                : {
                                    color: "#fff",
                                    background: "#0068B2",
                                    borderColor: "#0068B2",
                                  }
                            }
                          >
                            {(numberOptions?.sub_type || {})[
                              item.dic_item_code
                            ] || 0}
                          </Tag>
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className="inputBox">
                    <div className="label">公告类型</div>
                    <Select
                      mode="multiple"
                      value={typeList}
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="全部"
                      showArrow={true}
                      bordered={false}
                      maxTagCount="responsive"
                      className="subjectScopeSelect"
                      tagRender={tagRender}
                      filterOption={(inputValue, option) =>
                        option.children?.at(0)?.props?.title?.includes(inputValue)
                      }
                      onChange={async (value) => {
                        if (
                          value.length ===
                          (options?.event_type_list || []).length
                        ) {
                          await setTypeCheckAll(true);
                          await setTypeCheckAllIndeterminate(false);
                        } else {
                          await setTypeCheckAllIndeterminate(true);
                          await setTypeCheckAll(false);
                        }
                        if (value.length === 0) {
                          await setTypeCheckAllIndeterminate(false);
                        }
                        await setTypeList(value);
                        // getData(true);
                      }}
                      onFocus={() => {
                        setOldTypeList(getTypeList());
                        getConditionStatistics("公告类型");
                      }}
                      onBlur={() => {
                        if (!ArrayIsEqual(getOldTypeList(), getTypeList())) {
                          getOnePageData(true);
                        }
                      }}
                      onClear={async () => {
                        setTypeCheckAll(false);
                        setTypeCheckAllIndeterminate(false);
                        await setTypeList([]);
                        await getOnePageData(true);
                      }}
                      dropdownRender={(menu) =>
                        selectMenuLoading ? (
                          <MySpin></MySpin>
                        ) : (
                          <div className="subjectScopeSearchPoP">
                            <Checkbox
                              style={{ margin: "5px", marginLeft: "12px" }}
                              indeterminate={typeCheckAllIndeterminate}
                              checked={typeCheckAll}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setTypeList(
                                    options?.event_type_list || []
                                  );
                                } else {
                                  setTypeList([]);
                                }
                                setTypeCheckAll(e.target.checked);
                                setTypeCheckAllIndeterminate(false);
                                e.stopPropagation();
                              }}
                            >
                              全选
                            </Checkbox>
                            {menu}
                          </div>
                        )
                      }
                    >
                      {(options?.event_type_list || []).map(
                        (item, index) => (
                          <Option key={item} value={item}>
                            <a className="textItem" title={item}>
                              {item}
                            </a>
                            <Tag
                              style={
                                !(numberOptions?.event_type || {})[item]
                                  ? {
                                      color: "#535353",
                                      background: "#EFEFEF",
                                      borderColor: "#EFEFEF",
                                    }
                                  : {
                                      color: "#fff",
                                      background: "#0068B2",
                                      borderColor: "#0068B2",
                                    }
                              }
                            >
                              {(numberOptions?.event_type || {})[item] || 0}
                            </Tag>
                          </Option>
                        )
                      )}
                    </Select>
                  </div>
                  <div className="inputBox">
                    <div className="label">公告主体</div>
                    <Select
                      mode="multiple"
                      value={eventList}
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="全部"
                      showArrow={true}
                      bordered={false}
                      maxTagCount="responsive"
                      className="subjectScopeSelect"
                      tagRender={tagRender}
                      filterOption={(inputValue, option) =>
                        option.children?.at(0)?.props?.title?.includes(inputValue)
                      }
                      onChange={async (value) => {
                        if (
                          value.length ===
                          (options?.event_subject_list || []).length
                        ) {
                          await setEventCheckAll(true);
                          await setEventCheckAllIndeterminate(false);
                        } else {
                          await setEventCheckAllIndeterminate(true);
                          await setEventCheckAll(false);
                        }
                        if (value.length === 0) {
                          await setEventCheckAllIndeterminate(false);
                        }
                        await setEventList(value);
                        // getData(true);
                      }}
                      onFocus={() => {
                        setOldEventList(getEventList());
                        getConditionStatistics("公告主体");
                      }}
                      onBlur={() => {
                        if (!ArrayIsEqual(getOldEventList(), getEventList())) {
                          getOnePageData(true);
                        }
                      }}
                      onClear={async () => {
                        setEventCheckAll(false);
                        setEventCheckAllIndeterminate(false);
                        await setEventList([]);
                        await getOnePageData(true);
                      }}
                      dropdownRender={(menu) =>
                        selectMenuLoading ? (
                          <MySpin></MySpin>
                        ) : (
                          <div className="subjectScopeSearchPoP">
                            <Checkbox
                              style={{ margin: "5px", marginLeft: "12px" }}
                              indeterminate={eventCheckAllIndeterminate}
                              checked={eventCheckAll}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setEventList(
                                    (options?.event_subject_list || [])
                                  );
                                } else {
                                  setEventList([]);
                                }
                                setEventCheckAll(e.target.checked);
                                setEventCheckAllIndeterminate(false);
                                e.stopPropagation();
                              }}
                            >
                              全选
                            </Checkbox>
                            {menu}
                          </div>
                        )
                      }
                    >
                      {(options?.event_subject_list || []).map(
                        (item, index) => (
                          <Option key={item} value={item}>
                            <a className="textItem" title={item}>
                              {item}
                            </a>
                            <Tag
                              style={
                                !(numberOptions?.event_subject || {})[item]
                                  ? {
                                      color: "#535353",
                                      background: "#EFEFEF",
                                      borderColor: "#EFEFEF",
                                    }
                                  : {
                                      color: "#fff",
                                      background: "#0068B2",
                                      borderColor: "#0068B2",
                                    }
                              }
                            >
                              {(numberOptions?.event_subject || {})[item] || 0}
                            </Tag>
                          </Option>
                        )
                      )}
                    </Select>
                  </div>
                </div>
              </div>
              <div className="tableContent">
                {total === 0 ? (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <MyEmpty></MyEmpty>
                  </div>
                ) : (
                  <Scrollbars
                    autoHide={true}
                    autoHideTimeout={5000}
                    style={{
                      height: total > 10 ? "calc(100% - 50px)" : "100%",
                      width: "100%",
                    }}
                  >
                    <div className="cardListBox">
                      {publicOpinionList.map((item) => (
                        <AnnouncementPublicOpinionCard
                          dataTable={dataTable}
                          typeName={typeName}
                          getData={getData}
                          key={item?.id?.val}
                          info={item}
                        ></AnnouncementPublicOpinionCard>
                      ))}
                    </div>
                  </Scrollbars>
                )}
                {total > 10 ? (
                  <div className="paginationBox">
                    <Pagination
                      className="myPagination"
                      showQuickJumper
                      current={pageNumber}
                      total={total}
                      pageSize={pageSize}
                      pageSizeOptions={[10, 20, 50, 100]}
                      onChange={onChange}
                      showTotal={(total) => `共 ${total} 条`}
                      showSizeChanger={true}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </Scrollbars>
      </div>
    </div>
  );
};
export default AnnouncementPublicOpinion;
