import "./index.scss";
import React, { useEffect, useState } from "react";
import {
  Drawer,
  Button,
  Form,
  message,
  Input,
  Radio,
  Tooltip,
  Checkbox,
} from "antd";
import Scrollbars from "react-custom-scrollbars";
import { useParams } from "react-router-dom";
import { CloseOutlined, LinkOutlined } from "@ant-design/icons";
import MySpin from "../../../../../components/MySpin";
import DebounceSelect from "../../../../../components/DebounceSelect";
import projectApi from "../../../../../apis/project";
import { downloadBase64Img, isCnNewID } from "../../../../../unit/unit";
import MessageModal from "../../../../../components/MessageModal";
import { useGetState } from "ahooks";
const { TextArea } = Input;

const CreatCollenctionDrawer = (props) => {
  const {
    /* 标题 */
    title,
    /* 关闭触发的回调 */
    onCancel,
    /* 成功的回调 */
    onSuccess,
    visible,
    projectId,
    defaultCreatInfo,
    userInfo,
  } = props;
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [authorizationMethod, setAuthorizationMethod] = useState("message");
  const [isConfirm, setIsConfirm] = useState(false);
  const [upLoading, setUpLoading] = useState(false);

  const [smsMessages, setSmsMessages, getSmsMessages] = useGetState(null);

  const handleCancel = () => {
    onCancel && onCancel();
  };

  const handleSuccess = (type) => {
    form?.validateFields().then((res) => {
      if (!isConfirm) {
        message.info("请勾选确认信息!");
        return;
      }
      //修改
      if (defaultCreatInfo) {
        generateAuthFunc(type, res);
        return;
      }
      setUpLoading(true);
      let params = {
        type: res?.authorizationMethod === "message" ? 10 : 20,
        legal_person_name: res?.legalRepresentative,
        company_id: res.company?.company_id,
        corp_name: res.company?.name,
        legal_person_identity: res?.idNumber,
        tax_no: res?.taxNumber,
        auth_name: res.authorizedPerson,
      };
      if (res?.authorizationMethod === "message") {
        params.phone = res.telephone;
      }
      //新建
      projectApi.checkAuthorize(projectId, params).then((_res) => {
        if (_res.code === 200) {
          generateAuthFunc(type, res);
        } else if (_res.code === -1) {
          const canNext =
            _res.data.res_message ===
              "已经采集过，是否再次发送采集授权申请？" &&
            _res.data.detail?.includes("已经授权并采集过数据");
          MessageModal({
            title: "提示",
            description: _res.data.res_message,
            message: _res.data.detail,
            noBtn: !canNext,
            noIcon: true,
            cancelText: "不发送",
            okText: "继续发送",
            zIndex: 1111,
            onOk: () => {
              generateAuthFunc(type, res);
            },
            onCancel: () => {
              setUpLoading(false);
            }
          });
        } else {
          setUpLoading(false);
          message.error(_res.message);
        }
      });
    });
    // onSuccess && onSuccess();
  };

  //生成采集
  const generateAuthFunc = (type, res) => {
    // if (type !== "发送短信") {
    let params = {};
    if (defaultCreatInfo) {
      params = {
        data_id: defaultCreatInfo.id,
        type: res?.authorizationMethod === "message" ? 10 : 20,
        legal_person_identity: res?.idNumber,
        tax_no: res?.taxNumber,
        legal_person_name: defaultCreatInfo?.legal_person_name,
        company_id: defaultCreatInfo?.company_id,
        corp_name: defaultCreatInfo?.corp_name,
        auth_name: res.authorizedPerson,
      };
    } else {
      params = {
        type: res?.authorizationMethod === "message" ? 10 : 20,
        legal_person_name: res?.legalRepresentative,
        company_id: res.company?.company_id,
        corp_name: res.company?.name,
        legal_person_identity: res?.idNumber,
        tax_no: res?.taxNumber,
        auth_name: res.authorizedPerson,
      };
    }
    if (res?.authorizationMethod === "message") {
      params.phone = res.telephone;
    }
    setUpLoading(true);
    projectApi.generateAuth(projectId, params).then((_res) => {
      if (_res.code === 200) {
        if (type === "复制链接") {
          navigator.clipboard.writeText(_res.data.url);
          message.success("复制成功");
        } else if (type === "下载二维码") {
          downloadBase64Img(
            _res.data.qr_code_binary,
            `${
              defaultCreatInfo ? defaultCreatInfo.corp_name : res.company?.name
            }_财税数据采集二维码.png`
          );
        } else {
          message.success("生成成功，10分钟内授权人将收到信息，请及时查看");
        }
        onSuccess && onSuccess();
      } else {
        message.error(_res.message);
      }
      setUpLoading(false);
    });
    // } else {
    // }
  };

  const getConfigUrl = () => {
    projectApi.getConfigUrl().then((res) => {
      if (res.code === 200) {
        setSmsMessages(res.data.sms_content);
        setTimeout(() => {
          initDefaultCreatInfo();
        }, 100);
      } else {
        message.error(res.message);
        setSmsMessages(null);
      }
    });
  };

  const initDefaultCreatInfo = () => {
    if (defaultCreatInfo) {
      setAuthorizationMethod(
        defaultCreatInfo?.type === 10 ? "message" : "offline"
      );
      const obj = {
        company: defaultCreatInfo?.corp_name,
        taxNumber: defaultCreatInfo?.tax_no,
        legalRepresentative: defaultCreatInfo?.legal_person_name,
        idNumber: null,//defaultCreatInfo?.legal_person_identity
        authorizationMethod:
          defaultCreatInfo?.type === 10 ? "message" : "offline",
        telephone: defaultCreatInfo?.phone,
        authorizedPerson: defaultCreatInfo?.auth_name,
      };
      form?.setFieldsValue(obj);
      if (defaultCreatInfo?.auth_name) {
        valuesChange(defaultCreatInfo?.auth_name);
      }
    } else {
      setAuthorizationMethod("message");
      form?.resetFields();
    }
  };

  useEffect(() => {
    if (visible) {
      if (!smsMessages) {
        getConfigUrl();
      } else {
        initDefaultCreatInfo();
      }
      //   setLoading(true);
      //   getData();
    } else {
      //   setData({});
      //   setShowList([]);
      setIsConfirm(false);
      setUpLoading(false);
      setLoading(false);
      setSmsMessages(null);
      form.resetFields()
    }
  }, [visible]);

  const onFinish = () => {};

  const fetchComList = (username) => {
    if (!username) {
      return Promise.resolve([]);
    }
    return projectApi.getCompanyInfo(username).then((res) => {
      if(res.code !== 200) {
        message.error(res.message)
        return []
      }
      return res.data.map((item) => ({
        ...item,
        label: item.name,
        value: item.company_id,
      }));
    });
  };

  const companySelectChange = (info) => {
    const obj = {
      taxNumber: info?.credit_code,
      legalRepresentative: info?.legal_person_name,
      authorizedPerson: info?.legal_person_name,
    };
    form?.setFieldsValue(obj);
    valuesChange(info?.legal_person_name);
  };

  const valuesChange = (value) => {
    let str = getSmsMessages();
    const legal_person = value || "XXX";
    const user_name = userInfo?.user_name || "XXX";
    const channel_id = "（待发送成功会自动生成链接）";
    str = str?.replace("${legal_person}", legal_person);
    str = str?.replace("${user_name}", user_name);
    const index = str?.indexOf("请点击以下链接进行授权：");
    str = str?.slice(0, index + 12) + "（待发送成功会自动生成链接）";
    form.setFieldsValue({
      SMSContent: str,
    });
  };

  return (
    <Drawer
      title={defaultCreatInfo ? "再次发起采集授权" : "发起采集授权"}
      placement="right"
      width="600px"
      className="creatCollenctionDrawer"
      onClose={handleCancel}
      visible={visible}
      push={false}
      closable={false}
      destroyOnClose={true}
      zIndex={1080}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            handleCancel();
          }}
        />
      }
      footer={
        <div className="drawerFooterBtnBox">
          {authorizationMethod === "message" && (
            <Button
              onClick={() => {
                handleSuccess("发送短信");
              }}
              type="primary"
              loading={upLoading}
            >
              发送短信
            </Button>
          )}
          {authorizationMethod === "offline" && (
            <Button
              onClick={() => {
                handleSuccess("下载二维码");
              }}
              type="primary"
              loading={upLoading}
            >
              下载二维码
            </Button>
          )}
          {authorizationMethod === "offline" && (
            <Button
              onClick={() => {
                handleSuccess("复制链接");
              }}
              type="primary"
              loading={upLoading}
            >
              复制链接
            </Button>
          )}
          <Button
            onClick={() => {
              handleCancel();
            }}
          >
            取消
          </Button>
        </div>
      }
    >
      <Scrollbars
        autoHide={true}
        autoHideTimeout={5000}
        style={{
          height: `100%`,
          width: "100%",
        }}
      >
        {loading ? (
          <MySpin></MySpin>
        ) : (
          <div className="creatCollenctionDrawerContent">
            <Form
              form={form}
              autoComplete="off"
              layout="vertical"
              initialValues={{
                authorizationMethod: "message",
              }}
            >
              <Form.Item
                label="采集公司"
                name="company"
                rules={[
                  {
                    required: true,
                    message: "请选择采集公司",
                  },
                ]}
              >
                <DebounceSelect
                  selectChange={companySelectChange}
                  showSearch={true}
                  placeholder="请输入公司名称搜索"
                  fetchOptions={fetchComList}
                  changeClear={true}
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  disabled={!!defaultCreatInfo}
                  getPopupContainer={(triggerNode) =>
                    triggerNode.parentNode || document.body
                  }
                ></DebounceSelect>
              </Form.Item>

              <Form.Item
                label="企业税号"
                name="taxNumber"
                rules={[
                  {
                    required: true,
                    message: "请输入企业税号",
                  },
                ]}
              >
                <Input placeholder="请输入企业税号" allowClear />
              </Form.Item>

              <Form.Item
                label="法人代表"
                name="legalRepresentative"
                rules={[
                  {
                    required: true,
                    message: "请输入法人代表",
                  },
                ]}
              >
                <Input
                  placeholder="请输入法人代表"
                  disabled={!!defaultCreatInfo}
                  allowClear
                />
              </Form.Item>

              <Form.Item
                label="法人身份证号"
                name="idNumber"
                rules={[
                  {
                    required: true,
                    message: "请输入法人身份证号",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        value === "" ||
                        value === undefined ||
                        value === null
                      ) {
                        return Promise.resolve();
                      }
                      value = value?.toString();
                      if (!isCnNewID(value)) {
                        return Promise.reject(
                          new Error("请输入正确的法人身份证号")
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input placeholder="请输入法人身份证号" allowClear />
              </Form.Item>

              <Form.Item
                label="发送授权方式"
                name="authorizationMethod"
                rules={[
                  {
                    required: true,
                    message: "请选择发送授权方式",
                  },
                ]}
              >
                <Radio.Group
                  value={authorizationMethod}
                  onChange={(e) => {
                    setAuthorizationMethod(e.target.value);
                  }}
                >
                  <Tooltip
                    title={
                      <div style={{ fontSize: "12px" }}>
                        通过短信平台自动发送短信到授权人手机
                      </div>
                    }
                  >
                    <Radio value="message"> 系统短信 </Radio>
                  </Tooltip>
                  <Tooltip
                    title={
                      <div style={{ fontSize: "12px" }}>
                        下载链接或二维码后，由您自己通过短信、微信、QQ或其他联系方式发送到授权人手机
                      </div>
                    }
                  >
                    <Radio value="offline"> 线下发送 </Radio>
                  </Tooltip>
                </Radio.Group>
              </Form.Item>

              {authorizationMethod === "message" && (
                <Form.Item
                  label="授权人（具有税务系统登录账号的法人或办税人员）"
                  name="authorizedPerson"
                  rules={[
                    {
                      required: true,
                      message: "请输入授权人名称",
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => {
                      valuesChange(e.target.value);
                    }}
                    placeholder="请输入授权人名称"
                    allowClear
                  />
                </Form.Item>
              )}

              {authorizationMethod === "message" && (
                <Form.Item
                  label="授权人联系电话"
                  name="telephone"
                  rules={[
                    {
                      required: true,
                      message: "请输入授权人联系电话",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          value === "" ||
                          value === undefined ||
                          value === null
                        ) {
                          return Promise.resolve();
                        }
                        value = value?.toString();
                        var reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                        if (!reg.test(value)) {
                          return Promise.reject(
                            new Error("请输入正确的手机号")
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input placeholder="请输入授权人联系电话" allowClear />
                </Form.Item>
              )}

              {authorizationMethod === "message" && (
                <Form.Item label="短信内容" name="SMSContent">
                  <TextArea disabled={true} rows={5} />
                </Form.Item>
              )}
            </Form>
            <Checkbox
              checked={isConfirm}
              onChange={(e) => {
                setIsConfirm(e.target.checked);
              }}
            >
              <span style={{ color: "#0068B2", fontWeight: "400" }}>
                我已了解采集流程，并与被尽调公司法人沟通确认
              </span>
            </Checkbox>
          </div>
        )}
      </Scrollbars>
    </Drawer>
  );
};
export default CreatCollenctionDrawer;
